/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: dashboard.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 * 10-Jul-2023      SP14JUL23       Mercedes Zambrana   Added test page
 * 18-Jul-2023      SP28JUL23       Favio Figueroa      Added Flow List
 * 05-Aug-2023      SP11AUG23       Favio Figueroa      Add onBoard in flow with getting params
 * 17-Aug-2023      SP25AUG23       Mercedes Zambrana   Add Flow Node page
 *****************************************************************************/

import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import Common from "../../../components/common/Common";
import {AuthGuard} from "../../../auth/guard";

// ----------------------------------------------------------------------

// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
const AffiliatePaymentPeriodPAge = lazy(() => import('src/custom/pages/system/affiliatePaymentPeriod/affiliate-payment-period-page'));
const VentaPage = lazy(() => import('src/custom/pages/system/Sale/sale-page'));


export const customSystemRoutes = [
  {
    path: 'system',
    element: (
        <AuthGuard>
            <Common/>
            <DashboardLayout>
                <Suspense fallback={<LoadingScreen />}>
                    <Outlet />
                </Suspense>
            </DashboardLayout>
        </AuthGuard>
    ),
    children: [
      // { element: <IndexPage />, index: true },
      { element: <VentaPage />, index: true },
      // { path: 'affiliatePaymentPeriod', element: <AffiliatePaymentPeriodPAge /> },
      { path: 'venta', element: <VentaPage /> },
    ],
  },
];
