/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: layout.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 * 10-Aug-2022      SP11AUG23       Favio Figueroa      Remove Header and change width
 * 18-Aug-2023      SP25AUG23       Favio Figueroa      remove padding in main layout
 *****************************************************************************/

// ----------------------------------------------------------------------
import Main from '../dashboard/main';

type Props = {
  children: React.ReactNode;
};

export default function SimpleLayout({ children }: Props) {
  return (
    <Main sx={{width: '100%', padding: '0 0 0 0'}}>
      {children}
    </Main>
  );
}
