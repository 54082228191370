/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: StyledCancelButton.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION:
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      -----------------------
 * 10-Aug-2022      SP11AUG23       Favio Figueroa      Created
 *****************************************************************************/

import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";

const StyledCancelButton = styled(Button)({
    borderRadius: '4px',
    border: '2px solid #1A1A1A',
    backgroundColor:'#fff',
    color:'#1A1A1A',
    '&:hover': { color: '#fff' },
});

export default StyledCancelButton;