/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: SwitchesGroupPxp.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      SP11AUG23       Favio Figueroa      Created
 *****************************************************************************/

import React from 'react';
import {handleChange} from '../helpers/events';
import {StatesFormInterface} from '../types/config';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';

export interface SwitchesGroupPxpComponentProps {
    nameKey: string;
    states: StatesFormInterface;
}

const areEqual = (prev: any, next: any) => {
  const nextState = next.states[next.nameKey];
  const prevState = prev.states[prev.nameKey];
  return (
    prevState.value === nextState.value &&
        prevState.config.name === nextState.config.name &&
        prevState.endAdornmentStates === nextState.endAdornmentStates &&
        prevState.disabled === nextState.disabled &&
        prevState.error === nextState.error
  );
};


function SwitchesGroupPxpComponent({states, nameKey}: SwitchesGroupPxpComponentProps) {
  const state = states[nameKey];


    const [stateAux, setStateAux] = React.useState({
        gilad: true,
        jason: false,
        antoine: true,
    });


 /*   const handleChange = (event: { e: React.ChangeEvent<HTMLInputElement>; nameKey: string; dataValue: any; states: StatesFormInterface }) => {
        setStateAux({
            ...stateAux,
            [event.target.name]: event.target.checked,
        });
    };*/

  const { inputProps } = state.config;
  return (
    <span>
      <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">{state.config.label}</FormLabel>
      <FormGroup>
          {
              Object.entries(state.value).map(([nameKeySwitch, valueSwitch]: any) => {
                  return (
                      <FormControlLabel
                          control={
                              <Switch checked={valueSwitch.value} onChange={(event) => handleChange({
                                  e: event,
                                  states,
                                  nameKey,
                                  dataValue: {...state.value, [nameKeySwitch]: { ...valueSwitch, value: event.target.checked} },
                              })} name={nameKeySwitch} />
                          }
                          label={valueSwitch.label}
                      />
                  )
              })
          }
      </FormGroup>
      <FormHelperText>{state.config.helperText}</FormHelperText>
    </FormControl>
    </span>
  );
}

/**
 * A memoized component that will re-render only one of props described in areEqual change.
 */
// eslint-disable-next-line react/display-name
const SwitchesGroupPxp = React.memo(
  (props: SwitchesGroupPxpComponentProps) => <SwitchesGroupPxpComponent {...props} />,
  areEqual,
);
export default SwitchesGroupPxp;
