/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: FormPxp.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      SP11AUG23       Favio Figueroa      Created
 * 30-Nov-2023      SP24NOV23       Favio Figueroa      Added Container
 *****************************************************************************/

import React, { useEffect, useState, forwardRef } from 'react';
import { ConfigFormInterface, StatesFormInterface } from './types/config';
import DrawForm from './DrawForm';
import {useFormPxp} from './hooks/useFormPxp';
import Container from "@mui/material/Container";

export interface FormPxpProps {
  config: ConfigFormInterface;
  loading?: true | false;
  containerProps?: React.ComponentProps<typeof Container>; // Aquí incluyes containerProps
}
const FormPxp = forwardRef(({ config, loading = false, containerProps }: FormPxpProps, ref) => {
  console.log('containerProps',containerProps)
  const {renderInContainer = true} = config;

  const statesForm: StatesFormInterface = {};
  const [configuredConfig, setConfiguredConfig] = useState({});
  const [init, setInit] = useState(false);

  // when is mount the FormPxp we create a states for each one of attributes
  // eslint-disable-next-line no-restricted-syntax
  for (const prop of Object.entries(config.attributes)) {
    const [nameKey, configAttribute] = prop;
    // @ts-ignore
    statesForm[nameKey] = useFormPxp({ nameKey: nameKey, configuredAttribute: configAttribute });
  }


  useEffect(() => {

    if (Object.keys(configuredConfig).length === 0) {

      const findAttributesHasKeyDown: any = Object.values(
        config.attributes
      ).filter(attr => typeof attr.onKeyDown === 'function')
        .map((obj) => obj.name);

      setConfiguredConfig({
        findAttributesHasKeyDown
      });

    }
  }, [configuredConfig]);

  // mount
  useEffect(() => {
    if(init === false) {
      const { afterInitializeAttributes } = config;

      setInit(true);
      if(typeof afterInitializeAttributes === 'function') {
        afterInitializeAttributes({states: statesForm });
      }

    }
  });

  return renderInContainer ? (
      <Container
          sx={{mt:1}}
          {...containerProps}
      >
        {Object.keys(configuredConfig).length > 0 && (<DrawForm statesForm={statesForm} config={config} loadingFormExternal={loading} configuredConfig={configuredConfig} ref={ref} />)}
      </Container>
  ): (
      <>
        {Object.keys(configuredConfig).length > 0 && (<DrawForm statesForm={statesForm} config={config} loadingFormExternal={loading} configuredConfig={configuredConfig} ref={ref} />)}
      </>
  )

});

export default FormPxp;
