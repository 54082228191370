/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: confirmError.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION:
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 11-Sep-2022      SP22SEP23       Favio Figueroa      Created
 *****************************************************************************/

import React from 'react';
import ReactJson from 'react-json-view';

import cheerio from 'cheerio';
import { ConfirmDialog } from "../custom-dialog";
import useCommon from "../../hooks/use-common";
import { ENV } from "../../config-global";

function isHTML(str:string) {
    return /<[a-z][\s\S]*>/i.test(str);
}

const RenderMessage = ({error}:any) => {

    if (error && error.isError) {
        let errorMessage;

        if (typeof error.error === 'string') {
            // Verifica si el error es HTML
            if (isHTML(error.error)) {
                // Si es HTML, procesa el HTML recibido para extraer el mensaje de error
                const $ = cheerio.load(error.error);
                errorMessage = $('h2.text-danger').text() || 'An error occurred. Please contact your administrator.';
            } else {
                // Si no es HTML, asumimos que es un mensaje de error directo
                errorMessage = error.error;
            }
        } else if (error.error instanceof Error) {
            // Si el error es una instancia de Error, extraemos el mensaje de error
            errorMessage = error.error.message;
        } else if (error.error && error.error.message) {
            // Si el error es un objeto con una propiedad "message", tomamos ese mensaje
            errorMessage = error.error.message;
        } else if (error.error && error.error.error && error.error.error.extendedMessage) {
            // Si el error es un objeto con una propiedad "message", tomamos ese mensaje
            errorMessage = error.error.error.extendedMessage;
        } else if (error.error && error.error && typeof error.error === 'object') {
            // Si el error es un objeto con una propiedad "message", tomamos ese mensaje
            return <ReactJson src={error.error} theme="ocean" enableClipboard={false} />

        } else {
            // Si no podemos determinar el mensaje de error, mostramos un mensaje genérico
            errorMessage = 'An error occurred. Please contact your administrator.';
        }


        return <div>{errorMessage}</div>;
    }


    if(error && error.error.code && error.error.message) {
        return <div >{error.message}</div>;
    }

    if(ENV === 'development') {
        return <ReactJson src={error.error} theme="ocean" enableClipboard={false} />
    }
    return <div >{error.error && error.error.error.extendedMessage ? error.error.error.extendedMessage : 'CONTACT WITH YOUR ADMIN'}</div>;

}

const ConfirmError = () => {
    const {error, onResetError} = useCommon();
    const handleCloseConfirm = () => {
        onResetError();
    }
    return (
        <>
            {error.isError && <ConfirmDialog
                open={error.isError}
                onClose={handleCloseConfirm}
                title= "WARNING"
                content={<RenderMessage error={error} />}
                buttonText="OK"
            />}

        </>
    );
};

export default ConfirmError;