/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: PxpTableHead.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION:
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 25-Jul-2023      SP28JUL23       Favio Figueroa      Created
 * 26-Sep-2023      SP06OCT23       Favio Figueroa      Added logic for sorter by columns
 * 30-Jul-2024      7124375606      Favio Figueroa      Added logic for store
 *****************************************************************************/

import React, {useEffect, useState} from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import {ConfigTableInterface} from '../types';
import TableSortLabel from "@mui/material/TableSortLabel";
import useTable from "../../../hooks/use-table";

export interface PxpTableHeadProps {
    config: ConfigTableInterface;
    addParams: any;
}


interface sortSettingInterface {
    dir: "ASC" | "DESC";
    sort: string;
}
const PxpTableHead = ({config, addParams} :PxpTableHeadProps) => {

    const {tables, onSetTableState} = useTable();
    const defaultValueFromStore = tables && tables[config.name] && typeof tables[config.name] === 'object' ? tables[config.name] : undefined;
    const [sortSetting, setSortSetting] = useState<sortSettingInterface>({
        dir: config.store.getDataConfig?.doRequest.data.dir.toLowerCase(),
        sort: config.store.getDataConfig?.doRequest.data.sort,
        ...(defaultValueFromStore && {
            dir: defaultValueFromStore.dir,
            sort: defaultValueFromStore.sort
        })
    });
    const handleChangeSortSetting = (property:string) => {
        const isAsc = sortSetting.sort === property && sortSetting.dir === 'ASC';

        const newValue: sortSettingInterface = {
            dir: (isAsc) ? 'DESC' : 'ASC',
            sort: property
        };
        setSortSetting(newValue);
        onSetTableState({nameKey:config.name, data: { ...newValue }})

        addParams(newValue, true)
    }

    useEffect(() => {
        console.log('sortSetting', sortSetting.dir)
    }, [sortSetting]);

  return (
    <TableHead>
      <TableRow>
        {Object.entries(config.columns).map(
          ([nameKey, values], indexColumn) => {
            return (
              <React.Fragment key={`cell_${indexColumn}_${nameKey}`}>
                <TableCell
                  align="left"
                  padding="normal"
                  sortDirection={'asc'}
                >
                    {values.sortable === false ? (
                        values.label
                    ) : (
                        <TableSortLabel
                            active={nameKey === sortSetting.sort}
                            direction={sortSetting.dir === 'ASC' ? 'asc' : 'desc'}
                            onClick={() => handleChangeSortSetting(nameKey)}
                        >
                            {values.label}
                        </TableSortLabel>
                        )}
                </TableCell>
              </React.Fragment>
            );
          },
        )}
        <TableCell style={{ width: '5%' }} align="center">
              Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default PxpTableHead;
