import { Helmet } from 'react-helmet-async';
// sections
import {PaymentPeriodView} from "src/custom/sections/paymentPeriod/view";

// ----------------------------------------------------------------------

export default function PaymentPeriod() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Payment Period</title>
      </Helmet>

      <PaymentPeriodView />
    </>
  );
}
