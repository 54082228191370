/*****************************************************************************
 * Copyright(c) 2024 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: use-table.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION:
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 30-Jul-2024      7124375606      Favio Figueroa      Created
 *****************************************************************************/

import {useCallback} from 'react';
// redux
import {useDispatch, useSelector} from 'src/redux/store';
import {
    setTables
} from 'src/redux/slices/table';

export default function useTable() {
    const dispatch = useDispatch();

    const tables = useSelector((state) => state.table.tables);


    const onSetTableState = useCallback((value: any) => {
            dispatch(setTables(value));
        },
        [dispatch]
    );


    return {
        tables,
        onSetTableState,
    };
}
