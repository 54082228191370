/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: loading-screen.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 *****************************************************************************/

// @mui
import Box, { BoxProps } from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

// ----------------------------------------------------------------------

export default function LoadingScreen({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        px: 5,
        width: 1,
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      {...other}
    >
      <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
    </Box>
  );
}
