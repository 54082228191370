/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: use-params.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 *****************************************************************************/

import { useMemo } from 'react';
import { useParams as _useParams } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useParams() {
  const params = _useParams();

  return useMemo(() => params, [params]);
}
