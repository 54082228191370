/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: DrawForm.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      SP11AUG23       Favio Figueroa      Created
 * 25-AUG-2023      SP25AUG23       Favio Figueroa      Add SelectPxp and button cancel logic
 * 06-SEP-2023      SP08SEP23       Favio Figueroa      return statesForm in appento of autocomplete
 * 21-SEP-2023      SP22SEP23       Favio Figueroa      Integrated useApiMutation
 * 13-NOV-2023      MAIN            Favio Figueroa      Added AutoCompleteMultiple for rendering in the form
 * 12-Mar-2024      6065926588      Rensi Arteaga       add stateForm parameter in  appendTo textField
 *****************************************************************************/

import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import TextFieldPxp from './attributes/TextFieldPxp';
import {ConfigFormInterface, StatesFormInterface} from './types/config';
import AutoCompletePxp from './attributes/AutoCompletePxp';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {getMetadata, getValues } from './helpers/events';
import axios from 'axios';
import * as Yup from 'yup';
import DatePickerPxp from './attributes/DatePickerPxp';
import DateRangePickerPxp from './attributes/DateRangePickerPxp';
import GoogleReCaptchaV2 from './attributes/GoogleReCaptchaV2';
import FilePondPxp from "./attributes/FilePondPxp";
import MobileTimePickerPxp from "./attributes/MobileTimePickerPxp";
import SwitchesGroupPxp from "./attributes/SwitchesGroupPxp";
import {LoadingButton} from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';
import SelectPxp from "./attributes/SelectPxp";
import StyledCancelButton from "../../components/Button/CancelButton";
import { Stack } from '@mui/material';
import useApiMutation from "../../hooks/use-api-mutation";
import AutoCompleteMultiplePxp from "./attributes/AutoCompleteMultiplePxp";
import SwitchPxp from "./attributes/SwitchPxp";
import PaperButtonsPxp from "./attributes/PaperButtons";
import TimePickerPxp from './attributes/TimePickerPxp';
import RadioButtonsGroupPxp from './attributes/RadioButtonsGroup';
import ToggleButtonGroupPxp from './attributes/ToggleButtonGroup';


export interface DrawFormProps {
  statesForm: Record<any, any>;
  config: ConfigFormInterface;
  configuredConfig: any;
  loadingFormExternal?: boolean;
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const DrawForm = forwardRef(({ statesForm, config, configuredConfig, loadingFormExternal = false }: DrawFormProps, ref) => {
  const [loadingForm, setLoadingForm] = useState(false);

  const { submit: submitConfig, appendTo } = config;

  const handleDispatchSubmit = () => {
    if( typeof submitConfig.onSubmit === 'object') {
      if(submitConfig.onSubmit.instance) {
        return submitConfig.onSubmit.instance;
      } else {

      }

    } else {
      return () => {}
    }
  }
  const {isLoading, error, isError,mutate, data}:any =
      useApiMutation(
      (newData: any) =>
          (typeof submitConfig.onSubmit === 'object' && submitConfig.onSubmit.instance)
              ? submitConfig.onSubmit.instance({...submitConfig.onSubmit.config, data: newData})
              : axios({
                ...(typeof submitConfig.onSubmit === 'object' && {...submitConfig.onSubmit.config}),
                data: {
                  ...newData
                }
              }),
          {},
          {
            showLoading: false,
            ...(typeof submitConfig.onSuccess  === 'function' && { onSuccess: submitConfig.onSuccess }),
            ...(submitConfig.successMessage && {successMessage: submitConfig.successMessage}),
          }
  );
  useEffect(() => {
    setLoadingForm(isLoading)
  }, [isLoading, setLoadingForm])

  //event in form

  const sendData = () => {
    setLoadingForm(true);
    const values = getValues(statesForm);

    //todo add axios for request to any url config in form
    //setLoadingForm(false)
    const executeOnSubmit = () => {
      if( typeof submitConfig.onSubmit === 'object' ) {
        // @ts-ignore
        mutate({
          ...values,
          ...submitConfig.onSubmit.extraParams,
          ...(submitConfig.metadata && {__metadata: getMetadata(statesForm)} )
        });
      }
    }

    if(typeof submitConfig.onBeforeSubmit === 'function') {
      submitConfig.onBeforeSubmit(statesForm, values, (res:boolean) => {
        if(res === true) {
          executeOnSubmit();
        }else {
          setLoadingForm(false);
        }
      });
    } else {
      executeOnSubmit();

    }




  };

  // this factory is if exist some error then this  send to draw again the input with error or inputs
  const validateAllValues = (schema:any, values:any) => {
    try {
      schema.validateSync(values, { abortEarly: false });
    } catch (errors) {
      errors.inner.forEach((error:any) => {
        statesForm[error.path].setError({
          hasError: true,
          msg: error.message,
        });
      });
    }
  };

  const getSchemaValidation = (callback:any, nameGroup:string | undefined = undefined) => {
    const getValidations = () => {
      console.log('statesForm',statesForm)
      const validations = Object.entries(statesForm)
        .filter(
          ([, value]) =>
            typeof value.yupValidate === 'object' &&
                        ((nameGroup != undefined && value.config.group == nameGroup) ||
                            nameGroup === undefined) &&
                        /*typeof value.yupValidate.shape === 'object' &&
                        ((byGroup === true && value.group === nameGroup) ||
                            byGroup === false) &&*/
                        !value.hidden,
        )
        .reduce(
          (t, [nameKey, value]) => ({
            ...t,
            [nameKey]: value.yupValidate.shape,
          }),
          {},
        );
      console.log('validations',validations)
      return validations;
    };

    const schema = Yup.object().shape(getValidations());
    return callback(schema);
  };
  const getSchemaValidationByGroup = (groupName: string, callback:any) => {

    const values = getValues(statesForm);
    getSchemaValidation((schema:any) => {
      schema.isValid(values).then((valid:any) => {
        if (valid) {
        } else {
          // we need to know what are the field with errors and show the error
          validateAllValues(schema, values);
        }
        callback(valid);
      });
    }, groupName);


  }

  /*const validForm = (callbackValidForm:any) => {
    const values = getValues(statesForm);
    getSchemaValidation((schema:any) => {
      schema.isValid(values).then((valid:any) => {
        if (!valid) {
          validateAllValues(schema, values);
        }
        callbackValidForm(valid);
      });
    });
  }*/

  const validForm = () => {
    const values = getValues(statesForm);
    console.log('validForm',values)

    return new Promise((resolve, reject) => {
      getSchemaValidation((schema:any) => {
        schema.isValid(values).then((valid:any) => {
          if (!valid) {
            validateAllValues(schema, values);
          }
          resolve(valid);
        }).catch(reject);
      });
    });
  };

  const handleSubmitForm = (e:any) => {
    e && e.preventDefault();
    const values = getValues(statesForm);
    getSchemaValidation((schema:any) => {
      schema.isValid(values).then((valid:any) => {
        if (valid) {
          typeof submitConfig.onSubmit === 'function' ? submitConfig.onSubmit(statesForm, values) : sendData();

        } else {
          // we need to know what are the field with errors and show the error
          validateAllValues(schema, values);
        }
      });
    });

  };

  const resetForm = () => {
    Object.values(statesForm).forEach((state) => {
      state.reset();
    });
  };



  const { onEnterSubmit } = config;
  if (configuredConfig.findAttributesHasKeyDown.length > 0 || onEnterSubmit) {
    const handleUserKeyPress = (event: any) => {
      const { keyCode, target: { id } } = event;

      // only enter by some input
      if (configuredConfig.findAttributesHasKeyDown.includes(id)) {
        const { onKeyDown } = config.attributes[id];
        // eslint-disable-next-line no-unused-expressions
        onKeyDown && onKeyDown(event, statesForm);
      }

      // if the input does not have any event keydown but the config has enter submit and keycode enter
      if (!configuredConfig.findAttributesHasKeyDown.includes(id) && onEnterSubmit && keyCode === 13) {
        handleSubmitForm({
          event
        });
      }

    };

    useEffect(() => {
      window.addEventListener('keydown', handleUserKeyPress);
      return () => {
        window.removeEventListener('keydown', handleUserKeyPress);
      };
    }, [statesForm, handleUserKeyPress]);




  }

  useImperativeHandle(ref, () => {
    return {
      statesForm,
      getValues,
      getSchemaValidation,
      validateAllValues,
      getSchemaValidationByGroup,
      handleSubmitForm,
      validForm,
      loadingForm,
      setLoadingForm,
      resetForm

    };
  });



  const attributesForm:any = {};
  const attributesNodeForm = {};
  Object.keys(statesForm).map(nameKey => {
    const state = statesForm[nameKey];
    if(!state.hidden) {
      if(state.config.type === 'TextField') {
        if(state.config.appendTo) {
          // @ts-ignore
          attributesNodeForm[nameKey] = state.config.appendTo(<TextFieldPxp states={statesForm} nameKey={nameKey} />, state, statesForm);
        } else {
          // @ts-ignore
          attributesNodeForm[nameKey] = <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><TextFieldPxp states={statesForm} nameKey={nameKey} /></Grid>;
          attributesForm[nameKey] = <TextFieldPxp key={`attr_${nameKey}`} states={statesForm} nameKey={nameKey} />;

        }
      } else if(state.config.type === 'Dropdown') {
        if(state.config.appendTo) {
          // @ts-ignore
          attributesNodeForm[nameKey] = state.config.appendTo(<SelectPxp states={statesForm} nameKey={nameKey} />, state);
        } else {
          // @ts-ignore
          attributesNodeForm[nameKey] = <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><SelectPxp states={statesForm} nameKey={nameKey} /></Grid>;
          attributesForm[nameKey] = <SelectPxp key={`attr_${nameKey}`} states={statesForm} nameKey={nameKey} />;

        }
      } else if(state.config.type === 'AutoComplete') {
        if(state.config.appendTo) {
          //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
          // @ts-ignore
          attributesNodeForm[nameKey] = state.config.appendTo(<AutoCompletePxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />, statesForm);
        } else {
          // @ts-ignore
          attributesNodeForm[nameKey] = <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>;
          attributesForm[nameKey] = <AutoCompletePxp key={`attr_${nameKey}`} nameKey={nameKey} states={statesForm} />;

        }
      } else if(state.config.type === 'AutoCompleteMultiple') {
        if(state.config.appendTo) {
          //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
          // @ts-ignore
          attributesNodeForm[nameKey] = state.config.appendTo(<AutoCompleteMultiplePxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />, statesForm);
        } else {
          // @ts-ignore
          attributesNodeForm[nameKey] = <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompleteMultiplePxp nameKey={nameKey} states={statesForm} /></Grid>;
          attributesForm[nameKey] = <AutoCompleteMultiplePxp key={`attr_${nameKey}`} nameKey={nameKey} states={statesForm} />;

        }
      } else if(state.config.type === 'DatePicker') {
        if(state.config.appendTo) {
          //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
          // @ts-ignore
          attributesNodeForm[nameKey] = state.config.appendTo(<DatePickerPxp  key={`grid11111_${nameKey}`} nameKey={nameKey} states={statesForm} />);
        } else {
          // @ts-ignore
          attributesNodeForm[nameKey] = <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><DatePickerPxp nameKey={nameKey} states={statesForm} /></Grid>;
          attributesForm[nameKey] = <DatePickerPxp key={`attr_${nameKey}`} nameKey={nameKey} states={statesForm} />;

        }
      } else if(state.config.type === 'DateRangePicker') {
        if(state.config.appendTo) {
          //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
          // @ts-ignore
          attributesNodeForm[nameKey] = state.config.appendTo(<DateRangePickerPxp  key={`grid11111_${nameKey}`} nameKey={nameKey} states={statesForm} />);
        } else {
          // @ts-ignore
          attributesNodeForm[nameKey] = <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><DateRangePickerPxp nameKey={nameKey} states={statesForm} /></Grid>;
          attributesForm[nameKey] = <DateRangePickerPxp key={`attr_${nameKey}`} nameKey={nameKey} states={statesForm} />;

        }
      } else if(state.config.type === 'MobileTimePicker') {
        if(state.config.appendTo) {
          //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
          // @ts-ignore
          attributesNodeForm[nameKey] = state.config.appendTo(<MobileTimePickerPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
        } else {
          // @ts-ignore
          attributesNodeForm[nameKey] = <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><MobileTimePickerPxp nameKey={nameKey} states={statesForm} /></Grid>;
          attributesForm[nameKey] = <MobileTimePickerPxp key={`attr_${nameKey}`} nameKey={nameKey} states={statesForm} />;

        }
      } else if(state.config.type === 'GoogleReCaptchaV2') {
        if(state.config.appendTo) {
          //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
          // @ts-ignore
          attributesNodeForm[nameKey] = state.config.appendTo(<GoogleReCaptchaV2  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
        } else {
          // @ts-ignore
          attributesNodeForm[nameKey] = <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><GoogleReCaptchaV2 nameKey={nameKey} states={statesForm} /></Grid>;
          attributesForm[nameKey] = <GoogleReCaptchaV2 key={`attr_${nameKey}`} nameKey={nameKey} states={statesForm} />;

        }
      } else if(state.config.type === 'FilePond') {
        if(state.config.appendTo) {
          //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
          // @ts-ignore
          attributesNodeForm[nameKey] = state.config.appendTo(<FilePondPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
        } else {
          // @ts-ignore
          attributesNodeForm[nameKey] = <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><FilePondPxp nameKey={nameKey} states={statesForm} /></Grid>;
          attributesForm[nameKey] = <FilePondPxp key={`attr_${nameKey}`} nameKey={nameKey} states={statesForm} />;

        }
      } else if(state.config.type === 'Switches') {
        if(state.config.appendTo) {
          //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
          // @ts-ignore
          attributesNodeForm[nameKey] = state.config.appendTo(<SwitchesGroupPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
        } else {
          // @ts-ignore
          attributesNodeForm[nameKey] = <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><SwitchesGroupPxp nameKey={nameKey} states={statesForm} /></Grid>;
          attributesForm[nameKey] = <SwitchesGroupPxp key={`attr_${nameKey}`} nameKey={nameKey} states={statesForm} />;

        }
      } else if(state.config.type === 'Switch') {
        if(state.config.appendTo) {
          //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
          // @ts-ignore
          attributesNodeForm[nameKey] = state.config.appendTo(<SwitchPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
        } else {
          // @ts-ignore
          attributesNodeForm[nameKey] = <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><SwitchPxp nameKey={nameKey} states={statesForm} /></Grid>;
          attributesForm[nameKey] = <SwitchPxp key={`attr_${nameKey}`} nameKey={nameKey} states={statesForm} />;

        }
      } else if(state.config.type === 'PaperButtons') {
        console.log('PaperButtons appendTo')
        if(state.config.appendTo) {
          console.log('PaperButtons testttt')
          //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
          // @ts-ignore
          attributesNodeForm[nameKey] = state.config.appendTo(<PaperButtonsPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
        } else {
          console.log('PaperButtons else')

          // @ts-ignore
          attributesNodeForm[nameKey] = <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><PaperButtonsPxp nameKey={nameKey} states={statesForm} /></Grid>;
          attributesForm[nameKey] = <PaperButtonsPxp key={`attr_${nameKey}`} nameKey={nameKey} states={statesForm} />;

        }
      }  else if(state.config.type === 'TimePicker') {
        if(state.config.appendTo) {
          //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
          // @ts-ignore
          attributesNodeForm[nameKey] = state.config.appendTo(<TimePickerPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
        } else {
          // @ts-ignore
          attributesNodeForm[nameKey] = <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><TimePickerPxp nameKey={nameKey} states={statesForm} /></Grid>;
          attributesForm[nameKey] = <TimePickerPxp key={`attr_${nameKey}`} nameKey={nameKey} states={statesForm} />;

        }
      }  else if(state.config.type === 'RadioButtons') {
        if(state.config.appendTo) {
          //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
          // @ts-ignore
          attributesNodeForm[nameKey] = state.config.appendTo(<RadioButtonsGroupPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
        } else {
          // @ts-ignore
          attributesNodeForm[nameKey] = <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><RadioButtonsGroupPxp nameKey={nameKey} states={statesForm} /></Grid>;
          attributesForm[nameKey] = <RadioButtonsGroupPxp key={`attr_${nameKey}`} nameKey={nameKey} states={statesForm} />;

        }
      }  else if(state.config.type === 'RadioButtons') {
        if(state.config.appendTo) {
          //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
          // @ts-ignore
          attributesNodeForm[nameKey] = state.config.appendTo(<RadioButtonsGroupPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
        } else {
          // @ts-ignore
          attributesNodeForm[nameKey] = <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><RadioButtonsGroupPxp nameKey={nameKey} states={statesForm} /></Grid>;
          attributesForm[nameKey] = <RadioButtonsGroupPxp key={`attr_${nameKey}`} nameKey={nameKey} states={statesForm} />;

        }
      }  else if(state.config.type === 'ToggleButton') {
        if(state.config.appendTo) {
          //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
          // @ts-ignore
          attributesNodeForm[nameKey] = state.config.appendTo(<ToggleButtonGroupPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
        } else {
          // @ts-ignore
          attributesNodeForm[nameKey] = <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><ToggleButtonGroupPxp nameKey={nameKey} states={statesForm} /></Grid>;
          attributesForm[nameKey] = <ToggleButtonGroupPxp key={`attr_${nameKey}`} nameKey={nameKey} states={statesForm} />;

        }
      }
    }
  });


  return (
    <>
      {
        appendTo ?
          appendTo({
            submitButton: <LoadingButton
                color="primary"
                onClick={handleSubmitForm}
                loading={typeof submitConfig.onSubmit === 'function' ? loadingFormExternal : loadingForm}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                fullWidth
            >
              <span>{submitConfig.submitLabel || 'Submit'}</span>
            </LoadingButton>,
            ...(submitConfig.customButtonComponent && {
              submitButton: React.cloneElement(submitConfig.customButtonComponent, {
                onClick: handleSubmitForm,
              })
            }),
            attributesNodeForm: attributesNodeForm,
            statesForm: statesForm,
            attributesForm: attributesForm
          })
          :(
            <>
              <Grid container spacing={3}>
                {Object.keys(statesForm).map(nameKey => {
                  const state = statesForm[nameKey];
                  if(!state.hidden) {
                    if(state.config.type === 'TextField' || state.config.type === 'Dropdown') {
                      if(state.config.appendTo) {
                        //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
                        return state.config.appendTo(<TextFieldPxp states={statesForm} nameKey={nameKey} />);
                      } else {
                        return <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><TextFieldPxp states={statesForm} nameKey={nameKey} /></Grid>;
                      }
                    } else if(state.config.type === 'AutoComplete') {
                      if(state.config.appendTo) {
                      //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
                        return state.config.appendTo(<AutoCompletePxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
                      } else {
                        return <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>;

                      }
                    }  else if(state.config.type === 'AutoCompleteMultiple') {
                      if(state.config.appendTo) {
                      //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
                        return state.config.appendTo(<AutoCompleteMultiplePxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
                      } else {
                        return <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompleteMultiplePxp nameKey={nameKey} states={statesForm} /></Grid>;

                      }
                    } else if(state.config.type === 'DatePicker') {
                      if(state.config.appendTo) {
                      //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
                        return state.config.appendTo(<DatePickerPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
                      } else {

                        return <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><DatePickerPxp nameKey={nameKey} states={statesForm} /></Grid>;

                      }
                    } else if(state.config.type === 'DateRangePicker') {
                      if(state.config.appendTo) {
                      //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
                        return state.config.appendTo(<DateRangePickerPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
                      } else {

                        return <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><DateRangePickerPxp nameKey={nameKey} states={statesForm} /></Grid>;

                      }
                    } else if(state.config.type === 'MobileTimePicker') {
                      if(state.config.appendTo) {
                      //return state.config.appendTo(<Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><AutoCompletePxp nameKey={nameKey} states={statesForm} /></Grid>);
                        return state.config.appendTo(<MobileTimePickerPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
                      } else {
                        return <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><MobileTimePickerPxp nameKey={nameKey} states={statesForm} /></Grid>;

                      }
                    } else if(state.config.type === 'GoogleReCaptchaV2') {
                      if(state.config.appendTo) {
                        return state.config.appendTo(<GoogleReCaptchaV2  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
                      } else {
                        return <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><GoogleReCaptchaV2 nameKey={nameKey} states={statesForm} /></Grid>;

                      }
                    } else if(state.config.type === 'FilePond') {
                      if(state.config.appendTo) {
                        return state.config.appendTo(<FilePondPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
                      } else {
                        return <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><FilePondPxp nameKey={nameKey} states={statesForm} /></Grid>;

                      }
                    } else if(state.config.type === 'Switches') {
                      if(state.config.appendTo) {
                        return state.config.appendTo(<SwitchesGroupPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
                      } else {
                        return <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><SwitchesGroupPxp nameKey={nameKey} states={statesForm} /></Grid>;

                      }
                    } else if(state.config.type === 'Switch') {
                      if(state.config.appendTo) {
                        return state.config.appendTo(<SwitchPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
                      } else {
                        return <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><SwitchPxp nameKey={nameKey} states={statesForm} /></Grid>;

                      }
                    } else if(state.config.type === 'PaperButtons') {
                      console.log('PapperButtons')
                      if(state.config.appendTo) {
                        return state.config.appendTo(<PaperButtonsPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
                      } else {
                        return <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><PaperButtonsPxp nameKey={nameKey} states={statesForm} /></Grid>;

                      }
                    } else if(state.config.type === 'TimePicker') {
                      if(state.config.appendTo) {
                        return state.config.appendTo(<TimePickerPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
                      } else {
                        return <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><TimePickerPxp nameKey={nameKey} states={statesForm} /></Grid>;

                      }
                    } else if(state.config.type === 'RadioButtons') {
                      if(state.config.appendTo) {
                        return state.config.appendTo(<RadioButtonsGroupPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
                      } else {
                        return <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><RadioButtonsGroupPxp nameKey={nameKey} states={statesForm} /></Grid>;

                      }
                    } else if(state.config.type === 'RadioButtons') {
                      if(state.config.appendTo) {
                        return state.config.appendTo(<ToggleButtonGroupPxp  key={`grid_${nameKey}`} nameKey={nameKey} states={statesForm} />);
                      } else {
                        return <Grid key={`grid_${nameKey}`} item {...state.config.gridForm}><ToggleButtonGroupPxp nameKey={nameKey} states={statesForm} /></Grid>;

                      }
                    }
                  }


                })}
              </Grid>
              {submitConfig && (<Box
                mt={2}
                display="flex"
                justifyContent="flex-end"
              >
                <Stack spacing={1} direction="row">
                  {(submitConfig.cancel && submitConfig.cancel.onCancel) && (
                      <StyledCancelButton variant="contained" onClick={submitConfig.cancel.onCancel}>{submitConfig.cancel.label ? submitConfig.cancel.label : 'Cancel'}</StyledCancelButton>
                  )}
                  {(submitConfig.submitButton === true || submitConfig.submitButton === undefined) && (
                      <LoadingButton
                          color="primary"
                          onClick={handleSubmitForm}
                          loading={typeof submitConfig.onSubmit === 'function' ? loadingFormExternal : loadingForm}
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="contained"
                      >
                        <span>{submitConfig.submitLabel || 'Submit'}</span>
                      </LoadingButton>
                  )}
                </Stack>
              </Box>)}
            </>
          )
      }



    </>
  );
});

export default DrawForm;
