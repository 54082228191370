/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: use-api-mutation.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION:
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 11-Sep-2022      SP22SEP23       Favio Figueroa      Created
 *****************************************************************************/

import {useEffect} from "react";
import {useQuery} from 'react-query';
import useCommon from "./use-common";

interface OptionsApiInterface {
    showLoading: true | false;
}
const useApiQuery = (id:string | any[], fetch:any, options?:Record<any, any>, optionsApi?:OptionsApiInterface) => {
    const {onSetLoading, onSetError} = useCommon();
    const {isLoading, isLoadingError, error, isError, data, ...others} = useQuery(id, fetch, options);
    const { showLoading = true } = optionsApi || {};


    useEffect(() => {
        if(isError) {
            onSetError({
                isError,
                error
            })
        }
    }, [isError, error, onSetError])


    useEffect(() => {
        console.log('isLoading',isLoading)
        if(showLoading) {
            onSetLoading(isLoading);
        }
    }, [isLoading, onSetLoading, showLoading])

    return {
        isLoading, isLoadingError, error, isError, data, ...others
    };
};

export default useApiQuery;
