/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: config-navigation.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 *****************************************************************************/

import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------
import { BASENAME } from 'src/config-global';
import red from 'src/custom/assets/icons/naturalgo/business_red.svg'
import affiliates from 'src/custom/assets/icons/naturalgo/affiliates.svg'
import stock from 'src/custom/assets/icons/app/ic_dropbox.svg'
import {useAuthContext} from "../auth/hooks";

const icon = (name: string) => (
  <SvgColor src={`${BASENAME}/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const icon2 = (svg: string) => (
  <SvgColor src={svg} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  red: icon2(red),
  stock: icon2(stock),
  affiliates: icon2(affiliates),
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const user = useAuthContext();
  console.log('useNavData user',)

  const isAdmin = user?.user?.roles.roles.includes('Administrador');
  const isAffiliate = user?.user?.roles.roles.includes('oficina virtual');
  console.log('isAffiliate',user)
  console.log('isAffiliate',isAffiliate)

  const data = useMemo(() => {
      let navData = [];
      if (isAdmin) {
        /*navData.push({
          subheader: 'Admin',
          items: [
            //{ title: 'Dashboard Empresarial', path: '/dashboard/business', icon: ICONS.analytics },
            { title: 'Afiliado Pago Periodo', path: '/system/affiliatePaymentPeriod', icon: ICONS.affiliates },
          ],
        });*/
        navData.push({
          subheader: 'Venta',
          items: [
            //{ title: 'Dashboard Empresarial', path: '/dashboard/business', icon: ICONS.analytics },
            { title: 'Ventas', path: '/system/venta', icon: ICONS.affiliates },
          ],
        });
      }
      if (isAffiliate) {
        navData = [...navData,{
          subheader: 'Resumen Ejecutivo',
          items: [
            { title: 'Dashboard Global', path: '/dashboard/me', icon: ICONS.analytics },
            { title: 'Panel Mensual de Pagos', path: '/dashboard/paymentPeriod', icon: ICONS.dashboard },
            { title: 'Red de Afiliados', path: '/dashboard/red', icon: ICONS.red },
            { title: 'Mis Compras', path: '/dashboard/orders', icon: ICONS.order },
            { title: 'Mis Afiliados', path: '/dashboard/affiliates', icon: ICONS.affiliates },
          ],
        },
          {
            subheader: 'config',
            items: [
              // USER
              {
                title: 'User',
                path: 'user',
                icon: ICONS.user,
                children: [
                  { title: 'Perfil', path: '/dashboard/user/edit' },
                ],
              },
            ],
          },
          {
            subheader: 'Centro de Crecimiento',
            items: [
              {
                title: 'Gestión de Afiliados',
                path: 'manager_afiliate',
                icon: ICONS.user,
                children: [
                  { title: 'Afiliados', path: '/dashboard/managerAffiliate/list' },
                  { title: 'Registro Afiliados', path: '/dashboard/managerAffiliate/new' },
                ],
              },
              {
                title: 'Gestión de Inventario',
                path: 'manager_stock',
                icon: ICONS.stock,
                children: [
                  { title: 'Stock', path: '/dashboard/managerInventory/stock' },
                  { title: 'Historial', path: '/dashboard/managerInventory/history' },
                ],
              },
            ],
          }]
      }

      return navData;


    },
    [isAdmin, isAffiliate]
  );

  return data;
}
