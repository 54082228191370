/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: useAdornment.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      SP11AUG23       Favio Figueroa      Created
 *****************************************************************************/

import React, {useState} from 'react';

export interface UseAdornmentProps {
    nameKeyAdornment: string;
    adornment: any;
}


const useAdornment = ({adornment} :UseAdornmentProps) => {
  const [hidden, setHidden] = useState(adornment.hidden || false);
  const hide = () => setHidden(true);
  const show = () => setHidden(false);
  return {
    adornment,
    hidden,
    setHidden,
    hide,
    show,
  };
};

export default useAdornment;
