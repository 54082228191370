/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: config-navigation.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 *****************************************************************************/

import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------
import { BASENAME } from 'src/config-global';

// eslint-disable-next-line import/no-mutable-exports
let customConfigNav: any;

try {
  // eslint-disable-next-line global-require
  customConfigNav = require("src/custom/config-navigation").useNavData;
  console.log('customConfigNav',customConfigNav)
} catch (error) {
  console.error("Error import customSimpleRoutes:", error);
}

const icon = (name: string) => (
  <SvgColor src={`${BASENAME}/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('menu'),
        items: [
          { title: 'People', path: paths.dashboard.root, icon: ICONS.dashboard },
          { title: 'Communication', path: 'comunication', icon: ICONS.ecommerce },
          { title: 'Financials', path: 'financials', icon: ICONS.analytics },
          { title: 'Programming', path: 'programming', icon: ICONS.banking },
          { title: 'Kiosk', path: 'kiosk', icon: ICONS.booking },
          { title: 'Web Portal', path: 'web-portal', icon: ICONS.file },
          { title: 'Pages', path: 'pages', icon: ICONS.file },
          { title: 'Retail', path: 'retail', icon: ICONS.file },
          { title: 'Flows', path: 'flows', icon: ICONS.file },
          { title: 'Setup', path: 'setup', icon: ICONS.file },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'option2',
        items: [
          // USER
          {
            title: 'People',
            path: paths.dashboard.user.root,
            icon: ICONS.user,
            children: [
              { title: 'Prospects', path: paths.dashboard.user.root },
              { title: 'Members', path: paths.dashboard.user.cards },
              { title: 'Setup', path: paths.dashboard.user.list },
              { title: 'Reporting', path: paths.dashboard.user.new },
            ],
          },
        ],
      },

      // DEMO MENU STATES
      {
        subheader: 'others',
        items: [

          {
            title: t('menu_level'),
            path: '#/dashboard/menu_level',
            icon: ICONS.menuItem,
            children: [
              {
                title: t('menu_level_1a'),
                path: '#/dashboard/menu_level/menu_level_1a',
              },
              {
                title: t('menu_level_1b'),
                path: '#/dashboard/menu_level/menu_level_1b',
                children: [
                  {
                    title: t('menu_level_2a'),
                    path: '#/dashboard/menu_level/menu_level_1b/menu_level_2a',
                  },
                  {
                    title: t('menu_level_2b'),
                    path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b',
                    children: [
                      {
                        title: t('menu_level_3a'),
                        path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a',
                      },
                      {
                        title: t('menu_level_3b'),
                        path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
const getUSeNavData = customConfigNav || useNavData;

export { getUSeNavData as useNavData };
