/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: checkout.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 *****************************************************************************/

import { createSlice } from '@reduxjs/toolkit';
// types

// ----------------------------------------------------------------------

const initialState:any = {
  activeStep: 0,
  cart: [],
  subTotal: 0,
  total: 0,
  discount: 0,
  shipping: 0,
  billing: null,
  totalItems: 0,
};

const slice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    getCart(state, action) {

    },

    addToCart(state, action) {

    },

    deleteCart(state, action) {

    },

    resetCart(state) {
    },

    backStep(state) {
    },

    nextStep(state) {
    },

    gotoStep(state, action) {
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart
} = slice.actions;
