/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: PxpTableBody.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 25-Jul-2023      SP28JUL23       Favio Figueroa      Created
 *****************************************************************************/

import React from 'react';
import TableBody from '@mui/material/TableBody';
import PxpTableRow from './PxpTableRow';
import {ButtonsStatesInterface, ButtonStateInterface, ConfigTableInterface} from '../types';

export interface PxpTableBodyProps {
    rows: Record<any, any>[];
    config: ConfigTableInterface;
    buttonsStatesInterface: ButtonsStatesInterface

}


const PxpTableBody = ({rows, config, buttonsStatesInterface} :PxpTableBodyProps) => {
  return (
    <TableBody>
      {rows.map((row, index) => (
        <PxpTableRow row={row} key={row[config.store.storeId]} indexRow={index} config={config} buttonsStatesInterface={buttonsStatesInterface} />
      ))}
    </TableBody>
  );
};

export default PxpTableBody;
