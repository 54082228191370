/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: auth-guard.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 *****************************************************************************/

import { useEffect, useCallback, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
//
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
  jwt: paths.auth.jwt.login,
  auth0: paths.auth.auth0.login,
  firebase: paths.auth.firebase.login,

};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const router = useRouter();
  console.log('AuthGuard   ')

  const { authenticated, method } = useAuthContext();

  console.log('AuthGuard   ', authenticated)

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      const basename = process.env.REACT_APP_BASENAME || '';
      console.log('basename',basename)
      //const currentPath = window.location.pathname.replace(new RegExp(`^${basename}`), '');
      const currentPath = window.location.pathname.replace(new RegExp(`^${basename}/?`), '');

      const searchParams = new URLSearchParams({ returnTo: currentPath }).toString();

      const loginPath = loginPaths[method];

      const href = `${loginPath}?${searchParams}`;
      console.log('AuthGuard method',method)
      console.log('AuthGuard href',href)

      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [authenticated, method, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
