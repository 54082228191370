// @mui
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Card, { CardProps } from '@mui/material/Card';
// utils
import { fShortenNumber } from 'src/utils/format-number';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  list: {
    value: string | number;
    label: string;
  }[];
}

export default function WidgetConfig({ title, subheader, list, ...other }: Props) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box display="grid" gap={2} gridTemplateColumns="repeat(3, 1fr)" sx={{ p: 1 }}>
          {
              list.map((category, index) => (
                  <Paper key={`${category.label}_${index}`} variant="outlined" sx={{ py: 2.5, textAlign: 'center' }}>

                      <Typography variant="h6" sx={{ mt: 0.5 }}>
                          {category.value}
                      </Typography>

                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {category.label}
                      </Typography>
                  </Paper>
              ))
          }


      </Box>
    </Card>
  );
}
