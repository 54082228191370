/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: use-api-mutation.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION:
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 11-Sep-2022      SP22SEP23       Favio Figueroa      Created
 *****************************************************************************/

import {useEffect} from "react";
import {useMutation} from 'react-query';
import {useSnackbar} from "notistack";
import useCommon from "./use-common";

interface ownOptionsInterface {
    showLoading?: boolean;
    successMessage?:(smResponse:any) => string | any;
    onSuccess?:(data:any, variables:any) => void;
}

const useApiMutation = (mutationFn: any, options = {}, ownOptions?: ownOptionsInterface) => {
    const {onSetLoading, onSetError} = useCommon();
    const { enqueueSnackbar } = useSnackbar();
    const { showLoading = true } = ownOptions || {};

    const {isLoading, error, isError, isSuccess, data, ...mutation} = useMutation(mutationFn, {
        onSuccess: (dataSuccess, variables, context) => {
            if(ownOptions && ownOptions.onSuccess) {
                ownOptions.onSuccess(dataSuccess, variables); // this is for pxpForm if it is neccesary
            }

            if(ownOptions && ownOptions.successMessage) {
                const successMessage = ownOptions && ownOptions.successMessage ? ownOptions.successMessage(dataSuccess) : 'success';
                enqueueSnackbar(successMessage);
            }
        },
        ...options, // another option for useMutation
    });
    useEffect(() => {
        if(showLoading) {
            onSetLoading(isLoading)
        }
    }, [isLoading, onSetLoading, showLoading])

    useEffect(() => {
        if(isError) {
            onSetError({
                isError,
                error
            })
        }
    }, [isError, error, onSetError])



    return {isLoading, error, isError, isSuccess, data,...mutation};
};

export default useApiMutation;
