/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: PxpTableRow.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 25-Jul-2023      SP28JUL23       Favio Figueroa      Created
 * 02-Aug-2023      SP11AUG23       Favio Figueroa      add confirm dialog option
 * 02-Aug-2023      SP11AUG23       Rensi Arteaga       change button style
 *****************************************************************************/

import React, {useState} from 'react';
import TableRow from '@mui/material/TableRow';
import {TableCell} from '@mui/material';
import {
    ButtonsStatesInterface,
    ColumnInterface,
    ConfigTableInterface, ConfirmInterface,
} from '../types';
import IconButton from '@mui/material/IconButton';
import Iconify from "../../../components/iconify";
import Tooltip from "@mui/material/Tooltip";
import { useMediaQuery } from '@mui/material';
import CustomPopover, {usePopover} from "../../../components/custom-popover";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import {ConfirmDialog} from "../../../components/custom-dialog";
import {useBoolean} from "../../../hooks/use-boolean";

export interface PxpTableRowProps {
 row: Record<any, any>;
 config: ConfigTableInterface;
 indexRow: number;
    buttonsStatesInterface: ButtonsStatesInterface;

}

interface confirmActionInterface {
    title: string,
    content: string,
    subContent?: string,
    label: string,
    onClick?: any;
}

const PxpTableRow = ({row, config, indexRow, buttonsStatesInterface} :PxpTableRowProps) => {

    const isMobile = useMediaQuery('(max-width: 600px)');
    const popover = usePopover();
    const confirm = useBoolean();
    const [stateConfirmAction, setStateConfirmAction] = useState<confirmActionInterface>({
        title:'',
        content: '',
        subContent: '',
        label: '',
    });
    const changeConfirm = (button:any) => {
       setStateConfirmAction({
            title: button.confirm.title,
            content: button.confirm.content,
            subContent: button.confirm.subContent,
            label: button.confirm.label,
            onClick: () => {
                button.onClick(row);
                confirm.onFalse();
           }
        })
        confirm.onTrue();
    }


  const { actionsInCell } = config;
  const lengthActionsInCell = Object.keys(buttonsStatesInterface).length;
  // render column according to renderColumn or column type
  const renderColumn = (key:any, colConfig:ColumnInterface, row:any, index:any) => {
    if (colConfig.renderColumn) {
      return colConfig.renderColumn({row, column: colConfig});
    } else {
      return row[key];
    }
  };

  const stileButton = {
    borderRadius: '4px'
  };

  return (
      <>
    <TableRow hover >
      {Object.entries(config.columns).map(
        ([nameKey, values], indexColumn) => {
          return (
            <React.Fragment key={`cell_${indexColumn}_${nameKey}`}>
              <TableCell align="left">
                {renderColumn(nameKey, values, row, indexRow)}
              </TableCell>
            </React.Fragment>
          );
        },
      )}
        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        {
          actionsInCell && typeof actionsInCell === 'object' && (
            <>
                {!isMobile && Object.entries(buttonsStatesInterface).map(([nameButton, valueObjectButton]) => (
                    <Tooltip key={nameButton} title={valueObjectButton.label} placement="top" arrow>
                        <IconButton color={1==1 ? 'default' : 'default'}  onClick={() => valueObjectButton.confirm ? changeConfirm(valueObjectButton) : valueObjectButton.onClick(row)} >
                            {valueObjectButton.icon}
                        </IconButton>
                    </Tooltip>
                ))}

                {isMobile && (
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>)}

            </>
          )
        }


      </TableCell>
    </TableRow>

          <CustomPopover
              open={popover.open}
              onClose={popover.onClose}
              arrow="right-top"
              sx={{ width: 140 }}
          >
              {isMobile && Object.entries(buttonsStatesInterface).map(([nameButton, valueObjectButton]) => (
                  <MenuItem
                      onClick={() => {
                          popover.onClose();
                          valueObjectButton.onClick(row)
                      }}
                  >
                      {valueObjectButton.icon}
                      {valueObjectButton.label}
                  </MenuItem>
              ))}
          </CustomPopover>

          <ConfirmDialog
              open={confirm.value}
              onClose={confirm.onFalse}
              title={stateConfirmAction.title}
              content={stateConfirmAction.content}
              subContent={stateConfirmAction.subContent}
              action={
                  <Button variant="contained" color="error"  onClick={() => stateConfirmAction.onClick(row)} style={stileButton} autoFocus>
                      {stateConfirmAction.label}
                  </Button>
              }
          />

      </>
  );
};

export default PxpTableRow;
