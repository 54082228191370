/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: InitButton.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 25-Jul-2023      SP28JUL23       Favio Figueroa      Created
 *****************************************************************************/

import React, {useState} from 'react';
import {ButtonInterface, ButtonStateInterface} from '../types';

export interface InitButtonProps {
    button: ButtonInterface
}


const InitButton = ({button} :InitButtonProps) => {
  const { disabled: isDisabled = false } = button;

  const [disabled, setDisabled] = useState(isDisabled);
  const disable = () => setDisabled(true);
  const enable = () => setDisabled(false);
  const config: ButtonStateInterface = {
    ...button,
    ...{ disabled, setDisabled },
    disable,
    enable,
  };

  return config;
};

export default InitButton;

