/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: index.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION:
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 * 15-Jul-2022      SP18JUL23       Favio Figueroa      Added PxpTable
 *****************************************************************************/

import merge from 'lodash/merge';
import {Theme} from '@mui/material/styles';

import LoadingButton from './components/loading-button';
import Table from './components/table';

export function componentsOverrides(theme: Theme) {
    return merge(
        LoadingButton(theme),
        Table(theme),
    );
}
