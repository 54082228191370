import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------
import svg from 'src/custom/assets/illustrations/business_and_financial.svg'

function BusinessAndFinancial({ ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_LIGHTER = theme.palette.primary.lighter;

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
      <Box
          component="img"
          alt="empty content"
          src={svg}
          sx={{ width: 1, maxWidth: 160 }}
      />
  );
}

export default memo(BusinessAndFinancial);
