/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: TextFieldPxp.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      SP11AUG23       Favio Figueroa      Created
 * 25-AUG-2023      SP25AUG23       Favio Figueroa      Remove props that we dont use and fixed warnings
 *****************************************************************************/

// @ts-nocheck
import React from 'react';
import TextField from '@mui/material/TextField';
import {handleChange, handleBlur, handleMouseTriggerComponent} from '../helpers/events';
import {StatesFormInterface} from '../types/config';
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";

export interface TextFieldPxpComponentProps {
    nameKey: string;
    states: StatesFormInterface;
}

const areEqual = (prev: any, next: any) => {
  const nextState = next.states[next.nameKey];
  const prevState = prev.states[prev.nameKey];
  return (
    prevState.value === nextState.value &&
        prevState.config.name === nextState.config.name &&
        prevState.endAdornmentStates === nextState.endAdornmentStates &&
        prevState.disabled === nextState.disabled &&
        prevState.error === nextState.error
  );
};





function TextFieldPxpComponent({states, nameKey}: TextFieldPxpComponentProps) {
  const state = states[nameKey];
  const { inputProps } = state.config;
  console.log('state.config',state.config)
    return (
    <span>
      <TextField
        autoComplete={state.config.autoComplete || 'on'}
        id={state.config.name}
        inputRef={state.inputRef}
        label={state.config.label}
        type={state.config.textFieldType}
        onChange={e => handleChange({e, states, nameKey})}
        onBlur={() => handleBlur(state)}
        value={state.value}
        disabled={state.disabled}
        error={Boolean(state.error.hasError)}
        fullWidth
        helperText={state.error.hasError ? state.error.msg : state.config.helperText}
        name={nameKey}
        variant={state.config.variant}
        size={state.config.size}
        {...(state.config.type === 'Dropdown' && { select: true, SelectProps:{native: true} })}
        {...(state.config.inputProps && {
            InputProps: {
                ...state.config.inputProps,
                ...(state.endAdornmentStates && {
                    endAdornment:<InputAdornment position="end">
                        {Object.entries(state.endAdornmentStates).filter(([, valueEndAdornment]) => valueEndAdornment.hidden === false ).map(([nameKeyEndAdornment, valueEndAdornment]) => (
                            <IconButton
                                key={`${nameKeyEndAdornment}_adornment`}
                                aria-label="toggle open form for adding new data"
                                onClick={()=>valueEndAdornment.adornment.onClick({nameKeyEndAdornment, nameKey, state})}
                                onMouseDown={handleMouseTriggerComponent}
                            >
                                {valueEndAdornment.adornment.icon}
                            </IconButton>
                        ))}
                    </InputAdornment>
                }) }
        })}



        {...(state.config.originalProps && { ...state.config.originalProps })}

      >
        {state.config.type === 'Dropdown' && Array.isArray(state.config.options!) && state.config.options!.map((opt, indexOpt) => (
          // eslint-disable-next-line react/no-array-index-key
          <option key={indexOpt} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </TextField>
    </span>
  );
}

/**
 * A memoized component that will re-render only one of props described in areEqual change.
 */
// eslint-disable-next-line react/display-name
const TextFieldPxp = React.memo(
  (props: TextFieldPxpComponentProps) => <TextFieldPxpComponent {...props} />,
  areEqual,
);
export default TextFieldPxp;
