/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: config-global.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 * 15-Aug-2023      HOT-1           Favio Figueroa      adding condition for getting global variables from env or parent window
 * 25-Aug-2023      SP25AUG23       Favio Figueroa      adding FROM_HARMONY and add PrivateConfig
 * 12-Sep-2023      SP22SEP23       Favio Figueroa      adding ENV
 * 15-Oct-2023      SP20OCT23       Favio Figueroa      adding forceReload
 *****************************************************************************/

// routes
import * as PrivateConfig from './config-private';

export const FROM_HARMONY = window.parent && (window.parent as any).fromHarmony ? (window.parent as any).fromHarmony : 'N' as string;

export const HOST_API = window.parent && (window.parent as any).pxpndUrl ? (window.parent as any).pxpndUrl : process.env.REACT_APP_HOST_API as string;
export const TOKEN_API = window.parent && (window.parent as any).pxpndSecret ? (window.parent as any).pxpndSecret : process.env.REACT_APP_TOKEN_API as string;

export const BASENAME = process.env.REACT_APP_BASENAME;
export const PORT = process.env.PORT
export const ENV = process.env.REACT_APP_ENV

export const VENDOR_ID = window.parent && (window.parent as any).vendorId ? (window.parent as any).vendorId : process.env.REACT_APP_VENDOR_ID;
export const LOCATION_ID = window.parent && (window.parent as any).locationId ? (window.parent as any).locationId : process.env.REACT_APP_LOCATION_ID;

export const handleCloseApp = window.parent && (window.parent as any).closeFlow ? (window.parent as any).closeFlow : () => alert('no es iframe');
export const forceReload = window.parent && (window.parent as any).forceReload ? (window.parent as any).forceReload : () => false;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = 'dashboard/me'; // as '/dashboard'
export const PATH_HOME_PAGE = 'dashboard/me';

export const LAYOUT = {
    DASHBOARD: {
        backgroundColor: '#F7F7F7'
    }
};
export const PATH = {
    auth: {
        me: '/api/auth/login/me',
        login: '/api/auth/login/pxp',
        register: '/api/auth/login/register',
    }
};
export const THEME_SETTING = {
    themeMode: 'dark', // 'light' | 'dark'
    themeDirection: 'ltr', //  'rtl' | 'ltr'
    themeContrast: 'default', // 'default' | 'bold'
    themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
    themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
    themeStretch: false,
};
export const LOGIN_SCREEN_SETTING = {
    mainMessage: 'Mi GoCorp',
    form: {
        title: 'Iniciar sesión en GoCorp',
        username:{
            label: 'Codigo Afiliado',
        },
        password:{
            label: 'Password',
        },
        helpMessage: 'Diseñada para empoderar a los <strong>afiliados</strong> y maximizar su eficiencia en un entorno laboral moderno',
        button: {
            label: 'Login'
        }
    }
};

export const FIREBASE_API = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const FIREBASE_PREFIX_DOMAIN = process.env.REACT_APP_FIREBASE_PREFIX_DOMAIN;

export const LICENCE_MUI = process.env.REACT_APP_LICENCE_MUI || '';

export const privateConfig = PrivateConfig;

