/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: AutoCompleteMultiplePxp.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 11-Nov-2023      MAIN            Favio Figueroa      Created
 *****************************************************************************/

import React, {useState} from 'react';
import {CircularProgress, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {StatesFormInterface} from '../types/config';
import {handleChange} from '../helpers/events';
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface AutoCompleteMultipleComponentProps {
    nameKey: string;
    states: StatesFormInterface;
}

const areEqual = (prev:any, next:any) => {
  const nextState = next.states[next.nameKey];
  const prevState = prev.states[prev.nameKey];

  return (
    prevState.value === nextState.value &&
        prevState.name === nextState.name &&
            prevState.dataStore.loading === nextState.dataStore.loading &&
            prevState.dataStore.error === nextState.dataStore.error &&
        prevState.open === nextState.open &&
            prevState.disabled === nextState.disabled &&
            prevState.error === nextState.error &&
            prevState.dataStore.data === nextState.dataStore.data
  );
};


const AutoCompleteMultipleComponent = ({ states, nameKey } :AutoCompleteMultipleComponentProps) => {
  const state = states[nameKey];
  const { config: { label, size, variant, helperText, initialValue, inputProps }, value, dataStore, open, setOpen, disabled, error } = state;

  const { requestState, setRequestState, storeConfig } = dataStore!;
  const { renderOption, PaperComponent, filterOptions } = storeConfig!;
  const dataRows = dataStore!.storeConfig.dataReader.dataRows;
  const filter = dataStore!.storeConfig.dataReader.filter;
  console.log('dataStore',dataStore)
    const [selectedOptions, setSelectedOptions] = useState<any>(value);

    const [filteredOptions, setFilteredOptions] = useState<any>(null);


  const getDataFromDataRowName = () => {
      return (dataRows) ? dataStore?.data[dataRows] : dataStore?.data;
  }

    // if is neccesary we can build the data or filter
  const buildData = () => {
      if(filter) {
          const filterData = getDataFromDataRowName().filter(filter)
          return filterData;
      } else {
          return getDataFromDataRowName();
      }

  }

  let data = dataStore?.data
    ? Array.isArray(getDataFromDataRowName())
      ? buildData()
      : (getDataFromDataRowName()) ? [buildData()] : []
    : [];
  if(!data) {
    data = [];
  }

  console.log('data',data)




  const handleFocus = () => {
    if (requestState.load === false) {
      setRequestState((prevData: any) => ({
        ...prevData,
        load: true,
      }));
    }
  };


    const handleChangeMultiSelect = (event:any, selectedOptions:any, reason:any, option:any) => {
        console.log('handleChange',event)
        console.log('handleChange',selectedOptions)
        console.log('handleChange',reason)
        console.log('handleChange',option)
        let selectedOptionsAux = selectedOptions;

        if(reason === "selectOption") {
            //we need to verify if already you choose that row
            const findIndex = value.findIndex((v:any) => v[storeConfig.idDD] == option.option[storeConfig.idDD])
            if(findIndex >= 0) {
                selectedOptionsAux = selectedOptionsAux.filter((f:any) => f[storeConfig.idDD] != option.option[storeConfig.idDD]);
            }
        }

        handleChange({
            e: event,
            states,
            nameKey,
            dataValue: selectedOptionsAux,
        });

    };

  return (
      <Autocomplete
          multiple
          id={nameKey}
          size={size}
          //filterSelectedOptions
          value={value}
          options={
              data.map((i: any) => ({
                  ...i,
              }))// we need to send empty array for init form
          }
          loading={dataStore?.loading}
          disableCloseOnSelect
          getOptionLabel={(option:any) => (option ? option[storeConfig.descDD].toString() : ' ')}
          renderOption={(props, option:any, { selected }) => {
              console.log('renderOption value',value)
              console.log('renderOption [storeConfig.idDD]',[storeConfig.idDD])
              const findIndex = value.findIndex((v:any) => v[storeConfig.idDD] == option[storeConfig.idDD])
              const isSelected = (findIndex >= 0);
              console.log('isSelected',isSelected)

              return (
                  <li {...props}>
                      <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={isSelected}
                      />
                      {option.name}
                  </li>
              )
          }}
          style={{ width: 500 }}
          renderInput={(params) => (
              <TextField
                  {...params}
                  error={error.hasError}
                  helperText={state.error.hasError ? state.error.msg : state.config.helperText}
                  {...(dataStore?.error && { error: true, helperText: JSON.stringify(dataStore?.error) })}

                  label={label}
                  variant={variant || 'outlined'}

                  InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                          <>
                              {dataStore?.loading ? (
                                  <CircularProgress color="inherit" size={20} />
                              ) : null}

                              {inputProps &&
                                  inputProps.endAdornment &&
                                  inputProps.endAdornment}
                              {params.InputProps.endAdornment}
                          </>
                      ),
                  }}
              />
          )}
          onChange={handleChangeMultiSelect}

      />
  );
};

/**
 * A memoized component that will re-render only one of props described in areEqual change.
 */
const AutoCompleteMultiplePxp = React.memo(
  (props: AutoCompleteMultipleComponentProps) => <AutoCompleteMultipleComponent {...props} />,
  areEqual,
);

export default AutoCompleteMultiplePxp;
