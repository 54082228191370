/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: GoogleReCaptchaV2.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      SP11AUG23       Favio Figueroa      Created
 *****************************************************************************/

import React, {forwardRef} from 'react';
import {handleChange} from '../helpers/events';
import {StatesFormInterface} from '../types/config';
// @ts-ignore
import ReCAPTCHA from 'react-google-recaptcha';

export interface GoogleReCaptchaV2PxpComponentProps {
    nameKey: string;
    states: StatesFormInterface;
}


const GoogleReCaptchaV2 = forwardRef(
    ({states, nameKey}: GoogleReCaptchaV2PxpComponentProps) => {
        const state = states[nameKey];


        const {inputProps} = state.config;
        return (
            <span>
      <ReCAPTCHA
          ref={state.inputRef}
          theme="white"
          sitekey={state.config.siteKey}
          onChange={(captchaValue:any) => {
              handleChange({
                  e: undefined,
                  states,
                  nameKey,
                  dataValue: captchaValue,
              });
          }}
      />
    </span>
        );
    })

export default GoogleReCaptchaV2;
