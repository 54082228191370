// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import Box, { BoxProps } from '@mui/material/Box';
// theme
import { bgGradient } from 'src/theme/css';
import {useEffect, useState} from "react";

import useApiMutation from "../../../../hooks/use-api-mutation";
import {doRequest} from "../../../../utils/axios";
import img from "../../../assets/images/corp/character_11.png"
import {useCopyToClipboard} from "../../../../hooks/use-copy-to-clipboard";
// ----------------------------------------------------------------------

interface Props extends BoxProps {
  title?: string;
  price?: string;
  description?: string;
}

export default function BankingInviteFriends({
  price,
  title,
  description,
  sx,
  ...other
}: Props) {
  const theme = useTheme();
  const { copy } = useCopyToClipboard();
  const [valueCopy, setValueCopy] = useState('');

    const {isLoading, mutate, data}:any =
        useApiMutation(
            () =>
                doRequest({
                    url: '/api/afiliacion-nd/Corp/getLinkRegister',
                    method: 'POST',
                    data: {
                    },
                }),
            {},
            {
                showLoading: true,
                successMessage: () => 'Texto copiado al portapapeles!'
            },
        );

    const copyTextToClipboard = async (textToCopy:string) => {
        try {
            await navigator.clipboard.writeText(`https://corp.naturalgo.org/public/register/${textToCopy}`);
        } catch (err) {
            console.error("Error al copiar al portapapeles: ", err);
        }
    };
    useEffect(() => {

        if(data) {
            copy(`https://corp.naturalgo.org/public/register/${data}`);
            //copyTextToClipboard(data)

        }
        console.log('BankingInviteFriends data',data)
    }, [copy, data]);

    const handleClick = () => {
        mutate();
    }

  return (
    <Box {...other}>
      <Box
        component="img"
        alt="invite"
        src={img}
        sx={{
          left: 40,
          zIndex: 9,
          width: 140,
          position: 'relative',
          filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))',
          ...sx,
        }}
      />

      <Box
        sx={{
          mt: -15,
          color: 'common.white',
          borderRadius: 2,
          p: theme.spacing(16, 5, 5, 5),
          ...bgGradient({
            direction: '135deg',
            startColor: theme.palette.primary.main,
            endColor: theme.palette.primary.dark,
          }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box sx={{ whiteSpace: 'pre-line', typography: 'h4' }}>{title}</Box>
          <Box sx={{ typography: 'h2' }}>{price}</Box>
        </Stack>

        <Box sx={{ mt: 2, mb: 3, typography: 'body2' }}>{description}</Box>

        {/*<InputBase
          fullWidth
          placeholder="Email"
          endAdornment={
            <Button color="warning" variant="contained" size="small" sx={{ mr: 0.5 }}>
              Invite
            </Button>
          }
          sx={{
            pl: 1.5,
            height: 40,
            borderRadius: 1,
            bgcolor: 'common.white',
          }}
        />*/}
              <Button onClick={handleClick} color="warning" variant="contained" size="small" sx={{ mr: 0.5 }}>
                  Invitar
              </Button>


      </Box>
    </Box>
  );
}
