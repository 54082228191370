/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: use-common.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION:
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 12-Sep-2023      SP22SEP23       Favio Figueroa      Created
 *****************************************************************************/

import { useCallback } from 'react';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import {
  setError, setLoading, resetError
} from 'src/redux/slices/common';

export default function useCommon() {
  const dispatch = useDispatch();

  const common = useSelector((state) => state.common);
  const isLoading = useSelector((state) => state.common.isLoading);
  const error = useSelector((state) => state.common.error);

  const onSetLoading = useCallback((value:any) => {
      console.log('onSetLoading',value)
        dispatch(setLoading(value));
      },
      [dispatch]
  );

  const onSetError = useCallback((value:any) => {
        dispatch(setError(value));
      },
      [dispatch]
  );
  const onResetError = useCallback(() => {
        dispatch(resetError());
      },
      [dispatch]
  );

  return {
    common,
    isLoading,
    error,
    onSetLoading,
    onSetError,
    onResetError,
  };
}
