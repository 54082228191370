/*****************************************************************************
 * Copyright(c) 2024 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: ToggleButtonGroupPxp.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      main           Favio Figueroa      Created
 *****************************************************************************/

import React from 'react';
import {handleChange} from '../helpers/events';
import {StatesFormInterface} from '../types/config';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export interface ToggleButtonGroupPxpComponentProps {
    nameKey: string;
    states: StatesFormInterface;
}

const areEqual = (prev: any, next: any) => {
  const nextState = next.states[next.nameKey];
  const prevState = prev.states[prev.nameKey];
  return (
    prevState.value === nextState.value &&
        prevState.config.name === nextState.config.name &&
        prevState.endAdornmentStates === nextState.endAdornmentStates &&
        prevState.disabled === nextState.disabled &&
        prevState.error === nextState.error
  );
};


function ToggleButtonGroupPxpComponent({states, nameKey}: ToggleButtonGroupPxpComponentProps) {
  const state = states[nameKey];


    const [stateAux, setStateAux] = React.useState({
        gilad: true,
        jason: false,
        antoine: true,
    });


 /*   const handleChange = (event: { e: React.ChangeEvent<HTMLInputElement>; nameKey: string; dataValue: any; states: StatesFormInterface }) => {
        setStateAux({
            ...stateAux,
            [event.target.name]: event.target.checked,
        });
    };*/

  const { inputProps } = state.config;


  return (
    <ToggleButtonGroup
      value={state.value}
      exclusive
      onChange={(event:any, newSelection:any) => handleChange({e: event, states, nameKey, dataValue: newSelection})}
      aria-label="Platform"
    >
      {
        state?.config.options?.map((valueSwitch, index) => {
          return (
            <ToggleButton value={valueSwitch.value} >{valueSwitch.label}</ToggleButton>
          )
        })
      }
    </ToggleButtonGroup>
  );
}

/**
 * A memoized component that will re-render only one of props described in areEqual change.
 */
// eslint-disable-next-line react/display-name
const ToggleButtonGroupPxp = React.memo(
  (props: ToggleButtonGroupPxpComponentProps) => <ToggleButtonGroupPxpComponent {...props} />,
  areEqual,
);
export default ToggleButtonGroupPxp;
