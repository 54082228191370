/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: AutoCompletePxp.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      SP11AUG23       Favio Figueroa      Created
 * 19-SEP-2023      SP22SEP23       Favio Figueroa      Added error from dataStore
 * 02-OCT-2023      SP06OCT23       Favio Figueroa      Added handleBlur
 * 13-Nov-2023      MAIN            Favio Figueroa      Added logic for getting data when data rows is undefined
 *****************************************************************************/

import React from 'react';
import {CircularProgress, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {StatesFormInterface} from '../types/config';
import {handleBlur, handleChange, handleInputChange} from '../helpers/events';
import Chip from "@mui/material/Chip";

export interface AutoCompleteComponentProps {
    nameKey: string;
    states: StatesFormInterface;
}

const areEqual = (prev:any, next:any) => {
  const nextState = next.states[next.nameKey];
  const prevState = prev.states[prev.nameKey];

  return (
    prevState.value === nextState.value &&
        prevState.name === nextState.name &&
            prevState.dataStore.loading === nextState.dataStore.loading &&
            prevState.dataStore.error === nextState.dataStore.error &&
        prevState.open === nextState.open &&
            prevState.disabled === nextState.disabled &&
            prevState.error === nextState.error &&
            prevState.dataStore.data === nextState.dataStore.data
  );
};


const AutoCompleteComponent = ({ states, nameKey } :AutoCompleteComponentProps) => {
  const state = states[nameKey];
  const { config: { label, size, variant, helperText, initialValue, inputProps }, value, dataStore, open, setOpen, disabled, error } = state;

  const { requestState, setRequestState, storeConfig } = dataStore!;
  const { renderOption, PaperComponent, filterOptions } = storeConfig!;
  const dataRows = dataStore!.storeConfig.dataReader.dataRows;
  const filter = dataStore!.storeConfig.dataReader.filter;


  const getIddToRender = (dataInRow:any, idd:any) => {
    if(typeof idd === 'string') {
      console.log('getIddToRender is string', dataInRow[storeConfig.idDD])
      return dataInRow[storeConfig.idDD]
    }
    if(typeof idd === 'function') {
      console.log('getIddToRender is function', storeConfig.idDD(dataInRow))

      return storeConfig.idDD(dataInRow)
    }
  }
  const getDescDDToRender = (dataInRow:any, idd:any) => {
    if(typeof idd === 'string') {
      console.log('getIddToRender is string', dataInRow[storeConfig.descDD])
      return dataInRow[storeConfig.descDD]
    }
    if(typeof idd === 'function') {
      console.log('getIddToRender is function', storeConfig.descDD)
      console.log('getIddToRender is dataInRow', dataInRow)
      console.log('getIddToRender is function', storeConfig.descDD(dataInRow))

      return storeConfig.descDD(dataInRow)
    }
  }

  const getDataFromDataRowName = () => {

      const datos = dataStore?.data.pages.flatMap((page:any) => (dataRows) ? page[dataRows] :page) || [];

      //return (dataRows) ? dataStore?.data[dataRows] : dataStore?.data;
      return datos;
  }

    // if is neccesary we can build the data or filter
  const buildData = () => {
      if(filter) {
          const filterData = getDataFromDataRowName().filter(filter)
          return filterData;
      } else {
          return getDataFromDataRowName();
      }

  }

  let data = dataStore?.data
    ? Array.isArray(getDataFromDataRowName())
      ? buildData()
      : (getDataFromDataRowName()) ? [buildData()] : []
    : [];
  if(!data) {
    data = [];
  }





  const handleFocus = () => {
    if (requestState.load === false) {
      setRequestState((prevData: any) => ({
        ...prevData,
        load: true,
      }));
    }
  };

  const getOptionLabel = (option:any) => {
      if (option && getDescDDToRender(option, storeConfig.descDD) != null) {
          return getDescDDToRender(option, storeConfig.descDD).toString();
      }
      return '';
  }

    const managerScroll = (event:any) => {
        const listboxNode = event.currentTarget;
        console.log('managerScroll',Math.round(listboxNode.scrollTop + listboxNode.clientHeight) + 1)
        console.log('managerScroll listboxNode.scrollHeight',Math.round(listboxNode.scrollHeight))

        if ((Math.round(listboxNode.scrollTop + listboxNode.clientHeight) + 1) >= Math.round(listboxNode.scrollHeight)) {
            console.log('managerScroll entraaa')
            dataStore?.fetchNextPageData();

        }

    };



    return (
    <Autocomplete
      // key={index}
      {...(filterOptions && { filterOptions: filterOptions })}
      id={nameKey}
      size={size}
      filterSelectedOptions
      value={value}
      onInputChange={(e:any) =>
        handleInputChange(e, state)
      }
      onBlur={() => handleBlur(state)}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      //getOptionDisabled={() => true} // this is for disable some option, you want to put
      getOptionLabel={(option:any) => (option ? getOptionLabel(option) : ' ')}
      isOptionEqualToValue={(optionEq:any, valueEq:any) => {
        if (
        // we need to put this for not generating error when the autocomplete tries to find the value in the option
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
          initialValue && typeof initialValue === 'object' && getIddToRender(valueEq, storeConfig.idDD) === getIddToRender(initialValue, storeConfig.idDD)
          //initialValue && typeof initialValue === 'object' && valueEq[storeConfig.idDD] === initialValue[storeConfig.idDD]
        ) {
          return true;
        }
        return valueEq && getIddToRender(optionEq, storeConfig.idDD) === getIddToRender(valueEq, storeConfig.idDD);
      }}
      options={
        data.map((i: any) => ({
          ...i,
        }))// we need to send empty array for init form
      }
      loading={dataStore?.loading}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error.hasError}
          helperText={state.error.hasError ? state.error.msg : state.config.helperText}
          {...(dataStore?.error && { error: true, helperText: JSON.stringify(dataStore?.error) })}

          label={label}
          variant={variant || 'outlined'}
          autoComplete='nope'
          InputProps={{
            ...params.InputProps,
            //type: 'search',
            autoComplete: 'nope',
            name:'nope',
            endAdornment: (
              <>
                {dataStore?.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}

                {inputProps &&
                             inputProps.endAdornment &&
                             inputProps.endAdornment}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={(event, newValue) => {
        handleChange({
          e: event,
          states,
          nameKey,
          dataValue: newValue,
        });
      }}
      onFocus={handleFocus}
      renderOption={
        renderOption ? (props, option, { selected }) => renderOption(props, option, { selected }) : undefined
      }
      disabled={disabled}
      {...(PaperComponent && { PaperComponent: PaperComponent })}
      {...(state.config.originalProps && { ...state.config.originalProps })}
        ListboxProps={{onScroll: managerScroll }}

    />
  );
};

/**
 * A memoized component that will re-render only one of props described in areEqual change.
 */
const AutoCompletePxp = React.memo(
  (props: AutoCompleteComponentProps) => <AutoCompleteComponent {...props} />,
  areEqual,
);

export default AutoCompletePxp;
