import { useCallback } from 'react';
// @mui
import { Theme, useTheme, alpha, SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// theme
import { bgGradient } from 'src/theme/css';
// utils
import { fCurrency } from 'src/utils/format-number';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Carousel, { CarouselDots, useCarousel } from 'src/components/carousel';
import overlay2 from 'src/assets/images/overlay_2.jpg'
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import AvatarRank from "../../../components/AvatarRank";
// ----------------------------------------------------------------------

type ItemProps = {
    codigo: string;
    fecha_reg: string;
    nombre: string;
    nombre_completo2: string;
    pais: string;
    moneda: string;
    rango: string;
    nombre_completo_patrocinador: string;
    codigo_patrocinador: string;
};
type Props = {
  list: ItemProps[];
  sx?: SxProps<Theme>;
};

export default function BankingCurrentBalance({ list, sx }: Props) {
  const theme = useTheme();

  const carousel = useCarousel({
    fade: true,
    speed: 100,
    ...CarouselDots({
      sx: {
        right: 16,
        bottom: 16,
        position: 'absolute',
        color: 'primary.light',
      },
    }),
  });

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.grey[900], 0.8),
          imgUrl: overlay2,
        }),
        height: 262,
        borderRadius: 2,
        position: 'relative',
        color: 'common.white',
        '.slick-slider, .slick-list, .slick-track, .slick-slide > div': {
          height: 1,
        },
        '&:before, &:after': {
          left: 0,
          mx: 2.5,
          right: 0,
          zIndex: -2,
          height: 200,
          bottom: -16,
          content: "''",
          opacity: 0.16,
          borderRadius: 2,
          bgcolor: 'grey.500',
          position: 'absolute',
        },
        '&:after': {
          mx: 1,
          bottom: -8,
          opacity: 0.24,
        },
        ...sx,
      }}
    >
      <Carousel {...carousel.carouselSettings}>
        {list.map((card) => (
          <CardItem key={card.codigo} card={card} />
        ))}
      </Carousel>
    </Box>
  );
}

// ----------------------------------------------------------------------

type CardItemProps = {
  card: ItemProps;
};

function CardItem({ card }: CardItemProps) {
    console.log('CardItem',card)
  const { nombre_completo2, codigo, fecha_reg, rango, codigo_patrocinador, nombre_completo_patrocinador } = card;

  const currency = useBoolean();

  const popover = usePopover();


  return (
    <Stack justifyContent="space-between" sx={{ height: 1, p: 3 }}>
        <IconButton
          color="inherit"
          onClick={popover.onOpen}
          sx={{
            top: 8,
            right: 8,
            zIndex: 9,
            opacity: 0.48,
            position: 'absolute',
            ...(popover.open && {
              opacity: 1,
            }),
          }}
        >
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>

        <div>
          <Typography sx={{ mb: 0, typography: 'subtitle2', opacity: 0.48 }}>
            Afiliado
          </Typography>

          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography sx={{ typography: 'h4' }}>
              {codigo}
            </Typography>
              {rango.toUpperCase()}
              <Box sx={{ lineHeight: 0, px: 0.75, borderRadius: 0.5, mr: 1 }}>
                  {/*<Iconify width={24} icon="logos:mastercard" />*/}
                  <AvatarRank name={rango ? rango.toUpperCase() : ''} sx={{width: 100, height: 100}}/>
              </Box>
          </Stack>
        </div>

       {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ typography: 'subtitle1' }}
        >
          <Box sx={{ lineHeight: 0, px: 0.75, borderRadius: 0.5, mr: 1 }}>
              <Iconify width={24} icon="logos:mastercard" />
              <AvatarRank name={rango ? rango.toUpperCase() : ''} sx={{width: 100, height: 100}}/>
          </Box>

        </Stack>*/}

        <Stack direction="row" spacing={3}>
          <Stack spacing={1}>
            <Typography sx={{ typography: 'caption', opacity: 0.48 }}>Nombre</Typography>
            <Typography sx={{ typography: 'subtitle1' }}>{nombre_completo2}</Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography sx={{ typography: 'caption', opacity: 0.48 }}>Fecha Reg</Typography>
            <Typography sx={{ typography: 'subtitle1' }}>{moment(fecha_reg).format("DD/MMMM/YYYY")}</Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={3}>
          <Stack spacing={1}>
            <Typography sx={{ typography: 'caption', opacity: 0.48 }}>Patrocinador</Typography>
            <Typography sx={{ typography: 'subtitle1' }}>{`${nombre_completo_patrocinador}(${codigo_patrocinador})`}</Typography>
          </Stack>
        </Stack>

      </Stack>
  );
}
