/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: use-carousel.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 *****************************************************************************/

import Carousel, { Settings } from 'react-slick';
import { useRef, useCallback, useState, useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

type ReturnType = {
  currentIndex: number;
  nav: Carousel | undefined;
  carouselSettings: Settings;
  carouselRef: React.MutableRefObject<Carousel | null>;
  //
  onPrev: VoidFunction;
  onNext: VoidFunction;
  onSetNav: VoidFunction;
  onTogo: (index: number) => void;
  //
  setNav: React.Dispatch<React.SetStateAction<Carousel | undefined>>;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
};

export default function useCarousel(props?: Settings): ReturnType {
  const theme = useTheme();

  const carouselRef = useRef<Carousel | null>(null);

  const [currentIndex, setCurrentIndex] = useState(props?.initialSlide || 0);

  const [nav, setNav] = useState<Carousel | undefined>(undefined);

  const rtl = theme.direction === 'rtl';

  const carouselSettings = {
    arrows: false,
    dots: !!props?.customPaging,
    rtl,
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
    ...props,
    fade: !!(props?.fade && !rtl),
  };

  const onSetNav = useCallback(() => {
    if (carouselRef.current) {
      setNav(carouselRef.current);
    }
  }, []);

  const onPrev = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.slickPrev();
    }
  }, []);

  const onNext = useCallback(() => {
    console.log('currentIndex.currentIndex',currentIndex)
    console.log('currentIndex.currentIndex',nav)
    console.log('carouselRef.current',carouselRef.current)
    if (carouselRef.current) {
      carouselRef.current.slickNext();
    }
  }, [currentIndex, nav]);

  const onTogo = useCallback((index: number) => {
    if (carouselRef.current) {
      carouselRef.current.slickGoTo(index);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      console.log('resize')
      // Asegura que el ítem central esté en el medio al redimensionar la ventana
      if (carouselRef.current) {
        // @ts-ignore
        const {currentSlide} = carouselRef.current.innerSlider.state;
        console.log('currentSlide',currentSlide)
        //carouselRef.current.slickGoTo(currentSlide);
        // @ts-ignore
        const centerSlide = Math.floor(carouselRef.current.props.slidesToShow / 2);
        console.log('centerSlide',centerSlide)

        carouselRef.current.slickGoTo(centerSlide + 1);
      }
    };

    // Agregar el listener de redimensionamiento de la ventana
    window.addEventListener('resize', handleResize);

    // Eliminar el listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return {
    nav,
    carouselRef,
    currentIndex,
    carouselSettings,
    //
    onPrev,
    onNext,
    onTogo,
    onSetNav,
    //
    setNav,
    setCurrentIndex,
  };
}
