/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: flow.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 21-Aug-2022      SP25AUG23       Favio Figueroa      Created
 *****************************************************************************/

import { createSlice } from '@reduxjs/toolkit';
// types

// ----------------------------------------------------------------------

const initialState:any = {
  registerType: 'PRE-REGISTRO'
};

const slice = createSlice({
  name: 'affiliate',
  initialState,
  reducers: {
    setRegisterType(state, action) {
      const registerType = action.payload;
      console.log('registerType',registerType)
      state.registerType = registerType;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setRegisterType,
} = slice.actions;


//selectors
const affiliate = (state:any) => state.affiliate;
const registerType = (state:any) => state.affiliate.registerType;

export const selectors = {
  affiliate,
  registerType
}
