/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: use-confirm.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 04-Sep-2022      SP08SEP23       Favio Figueroa      Created
 * 12-Sep-2022      SP22SEP23       Favio Figueroa      fixed problem for store data
 *****************************************************************************/

import { useCallback, useState } from 'react';

interface ReturnType {
  open: boolean;
  data: any;
  onTrue: () => void;
  onFalse: () => void;
  onToggle: () => void;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  addData: (data:any)=>void;
}

export function useConfirm({open: defaultValue, data: extraData}: {open: boolean, data:any}): ReturnType {
  const [value, setValue] = useState({
    open: defaultValue,
    data: extraData
  });

  const onTrue = useCallback(() => {
    setValue((currentValue) => ({...currentValue, open: true}));
  }, []);

  const onFalse = useCallback(() => {
    setValue((currentValue) => ({...currentValue, open: false}))
  }, []);

  const onToggle = useCallback(() => {
    setValue((prev) => ({...prev, open: !prev.open}));
  }, []);

  const addData = useCallback((data:any) => {
    setValue((prev) => ({...prev, data}));
  }, []);

  return {
    ...value,
    onTrue,
    onFalse,
    onToggle,
    setValue,
    addData
  };
}
