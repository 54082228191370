// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card, { CardProps } from '@mui/material/Card';
// utils
import { fCurrency } from 'src/utils/format-number';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title: string;
  list: Record<any, any>[];
}

export default function BonusCurrentBalance({
  title,
                                              list, sx,
  ...other
}: Props) {

  const total = list.reduce((previousValue, currentValue) => previousValue + currentValue.value,0);

  return (
    <Card sx={{ p: 3, ...sx }} {...other}>
      <Typography variant="subtitle2" gutterBottom>
        {title}
      </Typography>

      <Stack spacing={2}>
        <Typography variant="h3">{total}</Typography>
        {
          list.map((l, index)=> (
              <Stack key={index} direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {l.label}
                </Typography>
                <Typography variant="subtitle1">{l.value}</Typography>
              </Stack>
          ))
        }


        <Stack direction="row" spacing={1.5}>
          <Button fullWidth variant="contained" color="warning">
            Detalle
          </Button>

         {/* <Button fullWidth variant="contained" color="primary">
            Transfer
          </Button>*/}
        </Stack>
      </Stack>
    </Card>
  );
}
