/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: table.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 *****************************************************************************/

import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------
// this not working we need to improve this theme
export default function PxpTable(theme: Theme) {
  return {
    PxpTableContainer: {
      styleOverrides: {
        backgroundColor: '#111'
      },
      '& .MuiToolbar-root': {
        backgroundColor: '#111',
      },
    }
  };
}
