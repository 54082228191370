/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: axios.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2023      SP14JUL23       Favio Figueroa      Created
 * 24-Jul-2023      SP28JUL23       Rensi Arteaga       endpoint getFlowRender
 * 11-Sep-2022      SP22SEP23       Favio Figueroa      Add fetch logic
 * 15-Oct-2022      SP20OCT23       Favio Figueroa      remove authorization and change end point for tempo
 *****************************************************************************/

import axios, { AxiosRequestConfig } from 'axios';
// config
import {HOST_API, PATH} from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    // Add other headers here if needed
  }
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.get(url, { ...config });
  return res.data;
};

export const fetcherFlow = async (args: string | [string, AxiosRequestConfig], flowId: number) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.get(url, { ...config, params:{flowId} });
  return res.data;
};

export const doRequestPost = async (endPoint: string, data: any) => {
  const res = await axiosInstance.post(endPoint, data);
  return res.data;
};

export const fetcherPost = async (args: string | [string, AxiosRequestConfig], body:any) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.post(url, { ...config ,...body });
  return res.data;
};

interface fetchInterface {
  url: string,
  method: 'POST' | 'GET',
  data: string | Record<any, any> | any;
  headers?: Record<any, any>

}
export function doRequest({url, method, data, headers = {}} : fetchInterface){
  console.log('doRequest', data)
  const config = {
    url,
    method,
    ...(method === 'GET' && {params: data}),
    ...(method === 'POST' && {data}),
    ...(headers && {headers})
  }
  return axiosInstance(config)
      .then((response) => response.data);


}


// ----------------------------------------------------------------------

export const endpoints = {
  flowBoard: '/api/flow-nd/Flow/getFlowRender',
  getParameterizedNode: '/api/flow-nd/Node/getParameterizedNode',
  auth: {
    me: PATH.auth.me,
    login: PATH.auth.login,
    register: PATH.auth.register,
  },
};

 