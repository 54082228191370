/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: dashboard.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 * 10-Jul-2023      SP14JUL23       Mercedes Zambrana   Added test page
 * 18-Jul-2023      SP28JUL23       Favio Figueroa      Added Flow List
 * 05-Aug-2023      SP11AUG23       Favio Figueroa      Add onBoard in flow with getting params
 * 17-Aug-2023      SP25AUG23       Mercedes Zambrana   Add Flow Node page
 *****************************************************************************/

import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import PaymentPeriodPage from 'src/custom/pages/dashboard/PaymentPeriod';
import BankingPage from 'src/custom/pages/dashboard/banking';
import Common from "../../../components/common/Common";
import {AuthGuard} from "../../../auth/guard";

// ----------------------------------------------------------------------

// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
//const BankingPage = lazy(() => import('src/custom/pages/dashboard/banking'));
//const PaymentPeriodPage = lazy(() => import('src/custom/pages/dashboard/PaymentPeriod'));
const EditUserPage = lazy(() => import('src/custom/pages/dashboard/user/edit'));
const ListAffiliatePage = lazy(() => import('src/custom/pages/dashboard/managerAffiliate/list-affiliate-page'));
const NewAffiliatePage = lazy(() => import('src/custom/pages/dashboard/managerAffiliate/new-affiliate-page'));
const ListMyOrdersPage = lazy(() => import('src/custom/pages/dashboard/myOrders/list-my-orders-page'));
const ListMyAffiliatesPage = lazy(() => import('src/custom/pages/dashboard/myAffiliates/list-my-affiliates-page'));
const ListStockPage = lazy(() => import('src/custom/pages/dashboard/managerInventory/list-stock-page'));
const ListHistoryPage = lazy(() => import('src/custom/pages/dashboard/managerInventory/list-history-page'));
// ----------------------------------------------------------------------

export const customDashboardRoutes = [
  {
    path: 'dashboard',
    element: (
        <AuthGuard>
            <Common/>
            <DashboardLayout>
                <Suspense fallback={<LoadingScreen />}>
                    <Outlet />
                </Suspense>
            </DashboardLayout>
        </AuthGuard>
    ),
    children: [
      // { element: <IndexPage />, index: true },
      { element: <BankingPage />, index: true },
      { path: 'me', element: <BankingPage /> },
      { path: 'orders', element: <ListMyOrdersPage /> },
      { path: 'affiliates', element: <ListMyAffiliatesPage /> },
      { path: 'paymentPeriod/:periodId?', element: <PaymentPeriodPage /> },
        {
            path: 'user',
            children: [
                { path: 'edit', element: <EditUserPage /> },
            ]
        },
        {
            path: 'managerAffiliate',
            children: [
                { path: 'list', element: <ListAffiliatePage /> },
                { path: 'new', element: <NewAffiliatePage /> },
            ]
        },
        {
            path: 'managerInventory',
            children: [
                { path: 'stock', element: <ListStockPage /> },
                { path: 'history', element: <ListHistoryPage /> },
            ]
        }
    ],
  },
];
