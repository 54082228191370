/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: confirm-dialog.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 * 01-Aug-2023      SP11AUG23       Rensi Arteaga       change style
 * 05-Aug-2023      SP11AUG23       Favio Figueroa      move to left the submit and cancel to right
 * 24-Aug-2023      SP25AUG23       Rensi Arteaga       change button position
 * 07-Sep-2023      SP08SEP23       Jaime Rivera        Cancel text button now is sent as parameter
 *****************************************************************************/

// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//
import { ConfirmDialogProps } from './types';

// ----------------------------------------------------------------------

export default function ConfirmDialog({
  title,
  content,
  subContent,
  action,
  open,
  onClose,
  buttonText = 'Cancel',
  ...other
}: ConfirmDialogProps) {

  const stileButton = {
    borderRadius: '4px'
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ textAlign: 'center', pb: 2 }}>{title}</DialogTitle>
      {content && <DialogContent sx={{ textAlign: 'center', typography: 'body2' }}> {content} </DialogContent>}
      {subContent && <DialogContent sx={{ textAlign: 'center', typography: 'body3', opacity: 0.7 }}> {subContent} </DialogContent>}
      <DialogActions color="primary" sx={{ justifyContent: 'center' }}>
        <Button variant="outlined" color="inherit" onClick={onClose} style={stileButton}>
          {buttonText}
        </Button>
        {action}
      </DialogActions>
    </Dialog>
  );
}
