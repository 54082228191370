/*****************************************************************************
 * Copyright(c) 2024 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: DateRangePickerPxp.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      main           Favio Figueroa      Created
 *****************************************************************************/

import React from 'react';
import {handleChange} from '../helpers/events';
import {StatesFormInterface} from '../types/config';

import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';

export interface DateRangePickerPxpComponentProps {
    nameKey: string;
    states: StatesFormInterface;
}

const areEqual = (prev: any, next: any) => {
  const nextState = next.states[next.nameKey];
  const prevState = prev.states[prev.nameKey];

    return (
    prevState.value === nextState.value &&
        prevState.config.name === nextState.config.name &&
        prevState.config.originalProps === nextState.config.originalProps &&
        prevState.error.hasError === nextState.error.hasError &&
        prevState.endAdornmentStates === nextState.endAdornmentStates &&
        prevState.error === nextState.error

);
};


function DateRangePickerPxpComponent({states, nameKey}: DateRangePickerPxpComponentProps) {
  const state = states[nameKey];
    const [value, setValue] = React.useState<Date | null>(new Date());


  const {inputProps} = state.config;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker

        label={state.config.label}
        value={state.value}
        disabled={state.disabled}
        onChange={(newValue:any, picker:any) => {
          console.log('DateRangePicker newValue',newValue)
          handleChange({
            e: undefined,
            states,
            nameKey,
            dataValue: newValue,
          });
        }}
        {...(state.config.originalProps && { ...state.config.originalProps })}

      />
    </LocalizationProvider>
  )
}

/**
 * A memoized component that will re-render only one of props described in areEqual change.
 */
// eslint-disable-next-line react/display-name
const DateRangePickerPxp = React.memo(
  (props: DateRangePickerPxpComponentProps) => <DateRangePickerPxpComponent {...props} />,
  areEqual,
);
export default DateRangePickerPxp;
