/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: RadioButtonsGroupPxp.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      SP11AUG23       Favio Figueroa      Created
 *****************************************************************************/

import React from 'react';
import {handleChange} from '../helpers/events';
import {StatesFormInterface} from '../types/config';


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';


export interface RadioButtonsGroupPxpComponentProps {
    nameKey: string;
    states: StatesFormInterface;
}

const areEqual = (prev: any, next: any) => {
  const nextState = next.states[next.nameKey];
  const prevState = prev.states[prev.nameKey];
  return (
    prevState.value === nextState.value &&
        prevState.config.name === nextState.config.name &&
        prevState.endAdornmentStates === nextState.endAdornmentStates &&
        prevState.disabled === nextState.disabled &&
        prevState.error === nextState.error
  );
};


function RadioButtonsGroupPxpComponent({states, nameKey}: RadioButtonsGroupPxpComponentProps) {
  const state = states[nameKey];


    const [stateAux, setStateAux] = React.useState({
        gilad: true,
        jason: false,
        antoine: true,
    });


 /*   const handleChange = (event: { e: React.ChangeEvent<HTMLInputElement>; nameKey: string; dataValue: any; states: StatesFormInterface }) => {
        setStateAux({
            ...stateAux,
            [event.target.name]: event.target.checked,
        });
    };*/

  const { inputProps } = state.config;


  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">{state.config.label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={state.value}
        onChange={(event:any) => handleChange({e: event, states, nameKey})}

      >
        {
          state?.config.options?.map((valueSwitch, index) => {
            return (
              <FormControlLabel
                value={valueSwitch.value}
                control={
                  <Radio />
                }
                label={valueSwitch.label}
              />
            )
          })
        }
      </RadioGroup>
    </FormControl>
  );
}

/**
 * A memoized component that will re-render only one of props described in areEqual change.
 */
// eslint-disable-next-line react/display-name
const RadioButtonsGroupPxp = React.memo(
  (props: RadioButtonsGroupPxpComponentProps) => <RadioButtonsGroupPxpComponent {...props} />,
  areEqual,
);
export default RadioButtonsGroupPxp;
