/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: index.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 *****************************************************************************/

import Carousel from 'react-slick';

export { default as CarouselDots } from './carousel-dots';
export { default as CarouselArrows } from './carousel-arrows';
export { default as CarouselArrowIndex } from './carousel-arrow-index';

export { default as useCarousel } from './use-carousel';

export default Carousel;
