/*****************************************************************************
 * Copyright(c) 2024 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: table.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION:
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 12-Sep-2022      7124375606      Favio Figueroa      Created
 *****************************************************************************/

import { createSlice } from '@reduxjs/toolkit';

interface TableState {
  tables: {
    [key: string]: any;
  };
}

const initialState: TableState = {
  tables: {},
};

const slice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setTables(state, action) {
      const { nameKey, data } = action.payload;
      console.log('action.payload',action.payload)
      state.tables[nameKey] = { ...state.tables[nameKey], ...data };

    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setTables,
} = slice.actions;


//selectors
const table = (state:any) => state.table;
const tables = (state:any) => state.table.tables;


export const selectors = {
  table,
  tables,
}
