import Avatar from '@mui/material/Avatar';
import React from 'react';
import diamante from 'src/custom/assets/images/rank/diamante.png'
import doble_diamante from 'src/custom/assets/images/rank/doble_diamante.png'
import embajador from 'src/custom/assets/images/rank/embajador.png'
import embajador_corona from 'src/custom/assets/images/rank/embajador_corona.png'
import imperial from 'src/custom/assets/images/rank/imperial.png'
import oro from 'src/custom/assets/images/rank/oro.png'
import plata from 'src/custom/assets/images/rank/plata.png'
import rubi from 'src/custom/assets/images/rank/rubi.png'
import triple_diamante from 'src/custom/assets/images/rank/triple_diamante.png'
import zafiro from 'src/custom/assets/images/rank/zafiro.png'

interface AvatarRankProps {
    sx?:Record<any, any>;
    name: string;
}












const AvatarRank = ({ sx = {}, name }: AvatarRankProps) => {
    const nameInUpperCase = name.toUpperCase();

    console.log('AvatarRank name',name)
    let img = '';
    switch (nameInUpperCase) {
        case 'CONSULTOR':
            img = diamante;
            break;
        case 'PLATA':
            img = plata;
            break;
        case 'ORO':
            img = oro;
            break;
        case 'RUBI':
            img = rubi;
            break;
        case 'SAFIRO':
            img = zafiro;
            break;
        case 'DIAMANTE':
            img = diamante;
            break;
        case 'DOBLE DIAMANTE':
            img = doble_diamante;
            break;
        case 'TRIPLE DIAMANTE':
            img = triple_diamante;
            break;
        case 'IMPERIAL':
            img = imperial;
            break;
        case 'ENBAJADOR':
            img = embajador;
            break;
        case 'EMBAJADOR CORONA':
            img = embajador_corona;
            break;
        default:
            img =''
            break;
    }
    return (
        <Avatar
            alt={nameInUpperCase}
            src={img}
            sx={{ ...sx }}
        />
    );
};

export default AvatarRank;