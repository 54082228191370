/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: path.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 *****************************************************************************/

// ----------------------------------------------------------------------

export const TRANSITION = {
  duration: 2,
  ease: [0.43, 0.13, 0.23, 0.96],
};

export const varPath = {
  animate: {
    fillOpacity: [0, 0, 1],
    pathLength: [1, 0.4, 0],
    transition: TRANSITION,
  },
};
