/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: common.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 12-Sep-2022      SP22SEP23       Favio Figueroa      Created
 *****************************************************************************/

import { createSlice } from '@reduxjs/toolkit';

const initialState:any = {
  isLoading: false,
  countLoading: 0,
  error: {
    isError: false,
    error: undefined
  },
};

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    resetError(state) {
      state.error = {
        isError: false,
        error: undefined
      };
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setLoading,
  setError,
  resetError,
} = slice.actions;
