/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: common.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION:
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 11-Sep-2022      SP22SEP23       Favio Figueroa      Created
 *****************************************************************************/

import React from 'react';
import ConfirmError from "./ConfirmError";
import { SplashScreenRedux } from "../loading-screen";

const Common = () => (
    <>
        <SplashScreenRedux />
        <ConfirmError/>
    </>
);


export default Common;