/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: App.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 * 07-Sep-2023      SP08SEP23       Jaime Rivera        Added QueryClientProvider
 * 15-Oct-2023      SP20OCT23       Favio Figueroa      Added AuthConsumer, AuthProvider
 *****************************************************************************/

// i18n
import 'src/locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// redux
import ReduxProvider from 'src/redux/redux-provider';
// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import {SettingsProvider, SettingsDrawer, SettingsValueProps} from 'src/components/settings';
// auth
import {AuthConsumer, AuthProvider} from 'src/auth/context/jwt';
//import {AuthConsumer, AuthProvider} from 'src/auth/context/firebase';
import { QueryClient, QueryClientProvider } from 'react-query';

import {useEffect, useState} from "react";
import { LicenseInfo } from '@mui/x-date-pickers-pro';
import {useLocation, useNavigate} from "react-router-dom";
import { forceReload, LICENCE_MUI, THEME_SETTING } from './config-global';


// Establecer tu clave de licencia
if(LICENCE_MUI) {
  LicenseInfo.setLicenseKey(LICENCE_MUI);
}

let icon:any;
try {
    // eslint-disable-next-line global-require
    icon = require("src/custom/assets/favion/ico.png");
    console.log('customPalette',icon)
} catch (error) {
    console.error("Error import customPalette:", error);
}
// ----------------------------------------------------------------------
function getFaviconEl() {
    return document.getElementById("favicon");
}
export default function App() {
  const charAt = `
 
───────────────────────────────────────────────────────────────────────────────────────────────────────────────
─██████████████───██████████████─████████████████───██████──██████─██████████████────██████──██████─██████████─
─██░░░░░░░░░░██───██░░░░░░░░░░██─██░░░░░░░░░░░░██───██░░██──██░░██─██░░░░░░░░░░██────██░░██──██░░██─██░░░░░░██─
─██░░██████░░██───██░░██████░░██─██░░████████░░██───██░░██──██░░██─██░░██████████────██░░██──██░░██─████░░████─
─██░░██──██░░██───██░░██──██░░██─██░░██────██░░██───██░░██──██░░██─██░░██────────────██░░██──██░░██───██░░██───
─██░░██──██░░██───██░░██──██░░██─██░░████████░░██───██░░██──██░░██─██░░██████████────██░░██──██░░██───██░░██───
─██░░██──██░░██───██░░██──██░░██─██░░░░░░░░░░░░██───██░░██──██░░██─██░░░░░░░░░░██────██░░██──██░░██───██░░██───
─██░░██──██░░██───██░░██──██░░██─██░░██████░░████───██░░██──██░░██─██████████░░██────██░░██──██░░██───██░░██───
─██░░██──██░░██───██░░██──██░░██─██░░██──██░░██─────██░░██──██░░██─────────██░░██────██░░██──██░░██───██░░██───
─██░░██████░░████─██░░██████░░██─██░░██──██░░██████─██░░██████░░██─██████████░░██────██░░██████░░██─████░░████─
─██░░░░░░░░░░░░██─██░░░░░░░░░░██─██░░██──██░░░░░░██─██░░░░░░░░░░██─██░░░░░░░░░░██────██░░░░░░░░░░██─██░░░░░░██─
  
  `;

  console.info(`%c${charAt}`, 'color: #5BE49B');

    useEffect(() => {
        console.log('icon',icon)
        if(icon) {
            // This will run when the page first loads and whenever the title changes
            document.title = process.env.REACT_APP_TITLE_WEB as string;
            const favicon = getFaviconEl();
            // @ts-ignore
            favicon.href = icon;
            console.log('ha iniciado el app',favicon)
        }



    });

  useScrollToTop();
  const queryClient = new QueryClient();

    useEffect(() => {
        const handleFocus = () => {
            // You can perform additional actions here
            if(forceReload()) {
                window.location.reload();
            }
        };
        const handleBlur = () => {
            console.log('The tab has lost focus.');
            // You can perform additional actions here
        };
        // Add event listeners when the component mounts
        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        // Remove event listeners when the component unmounts to prevent memory leaks
        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    }, []);
    const themeSetting = THEME_SETTING as SettingsValueProps;


    // logic for controller the navigate from post message from another app as harmony

    const navigate = useNavigate();
    const location = useLocation();
    const [prevLocation, setPrevLocation] = useState('');

    useEffect(() =>
        // Guarda la ruta actual antes de cambiar de página
         () => {
            setPrevLocation(location.pathname);
        }
    , [location]);

    useEffect(() => {
        const handleMessage = (event:any) => {
            if (event.data.action === 'goBack') {
                navigate(-1); // Retroceder en el historial de la aplicación React
            }
        };
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [navigate, prevLocation]);


  return (
    <AuthProvider>
      <ReduxProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider
            defaultSettings={{
                ...themeSetting
            }}
          >
            <ThemeProvider>
              <MotionLazy>
                <SnackbarProvider>
                  <QueryClientProvider client={queryClient}>
                    <SettingsDrawer />
                    <ProgressBar />
                      <AuthConsumer>
                          <Router />
                      </AuthConsumer>
                  </QueryClientProvider>
                </SnackbarProvider>
              </MotionLazy>
            </ThemeProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </ReduxProvider>
    </AuthProvider>
  );
}
