/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: MobileTimePickerPxp.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      SP11AUG23       Favio Figueroa      Created
 *****************************************************************************/

import React from 'react';
import TextField from '@mui/material/TextField';
import {handleChange} from '../helpers/events';
import {StatesFormInterface} from '../types/config';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {Stack} from '@mui/material';
import MobileTimePicker from '@mui/lab/MobileTimePicker';



export interface DatePickerPxpComponentProps {
    nameKey: string;
    states: StatesFormInterface;
}

const areEqual = (prev: any, next: any) => {
  const nextState = next.states[next.nameKey];
  const prevState = prev.states[prev.nameKey];
  return (
    prevState.value === nextState.value &&
        prevState.config.name === nextState.config.name &&
        prevState.error.hasError === nextState.error.hasError &&
        prevState.endAdornmentStates === nextState.endAdornmentStates &&
        prevState.error === nextState.error

);
};
const isValidDate = (date:any) => {
    return Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date.getTime());
}

function MobileTimePickerPxpComponent({states, nameKey}: DatePickerPxpComponentProps) {
  const state = states[nameKey];
    const [value, setValue] = React.useState<Date | null>(new Date());


  const {inputProps} = state.config;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={3}>
            <MobileTimePicker
                //disableFuture
                {...(state.config.inputFormat && { inputFormat:state.config.inputFormat })}
                ampm
                label={state.config.label}
                /*openTo="year"
                views={['year', 'month', 'day']}*/
                value={state.value}
                disabled={state.disabled}

                onChange={(newValue:any, picker:any) => {
                    handleChange({
                        e: undefined,
                        states,
                        nameKey,
                        dataValue: newValue,
                    });


                }}
                renderInput={(params:any) => <TextField {...params}
                                                    helperText={state.error.hasError ? state.error.msg : state.config.helperText}
                                                    error={Boolean(state.error.hasError)}
                                                    fullWidth
                                                    variant={state.config.variant}
                                                    size={state.config.size}



                />}
                {...(state.config.originalProps && { ...state.config.originalProps })}


            />
        </Stack>
    </LocalizationProvider>
  );
}

/**
 * A memoized component that will re-render only one of props described in areEqual change.
 */
// eslint-disable-next-line react/display-name
const MobileTimePickerPxp = React.memo(
  (props: DatePickerPxpComponentProps) => <MobileTimePickerPxpComponent {...props} />,
  areEqual,
);
export default MobileTimePickerPxp;
