/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: flow.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 21-Aug-2022      SP25AUG23       Favio Figueroa      Created
 * 13-Sep-2023      SP22SEP23       Favio Figueroa      Add changesInForm, forceSave
 *****************************************************************************/

import { createSlice } from '@reduxjs/toolkit';
// types

// ----------------------------------------------------------------------

const initialState:any = {
  emailVerified: false,
  phoneVerified: false
};

const slice = createSlice({
  name: 'user_afil',
  initialState,
  reducers: {
    setVerifiedEmail(state, action) {
      const emailVerified = action.payload;
      console.log('emailVerified',emailVerified)
      state.emailVerified = emailVerified;
    },
    setPhoneVerified(state, action) {
      const phoneVerified = action.payload;
      console.log('phoneVerified',phoneVerified)
      state.phoneVerified = phoneVerified;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setVerifiedEmail,
    setPhoneVerified
} = slice.actions;


//selectors
const emailVerified = (state:any) => state.userAfil.emailVerified;
const phoneVerified = (state:any) => state.userAfil.phoneVerified;

export const selectors = {
  emailVerified,
  phoneVerified
}

