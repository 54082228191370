/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: SwitchesPxp.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 30-Nov-2023      SP24AUG23       Favio Figueroa      Created
 *****************************************************************************/

import React, {useEffect} from 'react';
import {handleChange} from '../helpers/events';
import {StatesFormInterface} from '../types/config';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';

export interface SwitchesGroupPxpComponentProps {
    nameKey: string;
    states: StatesFormInterface;
}

const areEqual = (prev: any, next: any) => {
  const nextState = next.states[next.nameKey];
  const prevState = prev.states[prev.nameKey];
  return (
    prevState.value === nextState.value &&
        prevState.config.name === nextState.config.name &&
        prevState.endAdornmentStates === nextState.endAdornmentStates &&
        prevState.disabled === nextState.disabled &&
        prevState.error === nextState.error
  );
};


function SwitchPxpComponent({states, nameKey}: SwitchesGroupPxpComponentProps) {
  const state = states[nameKey];

  return (
      <FormControlLabel
          key={state.nameKey}
          label={state.config.label}
          labelPlacement='start'
          disabled={state.disabled}
          control={
              <Switch checked={state.value}
                      onChange={e =>handleChange({e, states, nameKey})}
                      name={state.nameKey}
                      {...(state.config.originalProps && { ...state.config.originalProps })}

              />
          }
          {...(state.config.originalFormControlProps && { ...state.config.originalFormControlProps })}

      />
  );
}

/**
 * A memoized component that will re-render only one of props described in areEqual change.
 */
// eslint-disable-next-line react/display-name
const SwitchPxp = React.memo(
  (props: SwitchesGroupPxpComponentProps) => <SwitchPxpComponent {...props} />,
  areEqual,
);
export default SwitchPxp;
