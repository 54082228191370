// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
// utils
import { fShortenNumber, fCurrency } from 'src/utils/format-number';
// components
import Iconify from 'src/components/iconify';
import { Card } from '@mui/material';
import Button from "@mui/material/Button";

// ----------------------------------------------------------------------

type Props = {
  icon: string;
  color?: string;
  data?: any;
    handleViewDetail: ({level}:any) => void;
};

export default function LevelAnalytic({ color, icon, data, handleViewDetail }: Props) {

  return (
    <Stack
      spacing={2.5}
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ width: 1, minWidth: 300 }}
    >


      <Stack alignItems="center" justifyContent="center" sx={{ position: 'relative' }}>
        <Iconify icon={icon} width={32} sx={{ color, position: 'absolute' }} />

        <CircularProgress
            variant="determinate"
            value={100}
            size={56}
            thickness={2}
            sx={{ color, opacity: 0.48 }}
        />

        <CircularProgress
            variant="determinate"
            value={100}
            size={56}
            thickness={3}
            sx={{
              top: 0,
              left: 0,
              opacity: 0.48,
              position: 'absolute',
              color: (theme) => alpha(theme.palette.grey[500], 0.16),
            }}
        />
      </Stack>
      <Stack>

          <Typography variant="subtitle2" gutterBottom>
            Nivel: {data.nivel}
          </Typography>
          <Typography variant="h3">{data.generaciones_cv_monto.toFixed(2)}</Typography>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
             CV
            </Typography>
            <Typography variant="body2">{data.total_cv}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
             %
            </Typography>
            <Typography variant="body2">{data.porcentaje}%</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              PV
            </Typography>
            <Typography variant="body2">{data.total_pv}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Activos
            </Typography>
            <Typography variant="body2">{data.activos}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              InActivos
            </Typography>
            <Typography variant="body2">{data.inactivos}</Typography>
          </Stack>
          <Stack mt={2}>
              <Button variant="contained" color="primary" onClick={() => handleViewDetail(data.nivel)}>
                  Ver Detalle
              </Button>
          </Stack>
      </Stack>

    </Stack>
  );
}
