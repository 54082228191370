import { Theme, alpha } from '@mui/material/styles';
import { GridRowId, DataGridPro } from '@mui/x-data-grid-pro';

export default function CustomDataGridPro(theme: Theme) {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
         /* '& .MuiDataGrid-root': {
            backgroundColor: theme.palette.primary.darker,
            color: '#fff', // Cambia el color del texto si es necesario
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.primary.darker,
            color: '#fff', // Cambia el color del texto si es necesario
          },*/
          '& .MuiDataGrid-columnHeader--pinnedRight': {
            color: '#fff', // Color personalizado,
            backgroundColor: theme.palette.background.neutral,
          },
          '& .MuiDataGrid-columnHeader--pinnedLeft': {
            color: '#fff', // Color personalizado,
            backgroundColor: theme.palette.background.neutral,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: 14,
            fontWeight: theme.typography.fontWeightSemiBold,
            color: theme.palette.text.secondary,


          },
          '& .MuiDataGrid-container--top [role=row]': {


            backgroundColor: theme.palette.background.neutral,

            //backgroundColor: theme.palette.primary.darker,
            //color: '#fff', // Cambia el color del texto si es necesario
          },
          '& .MuiDataGrid-virtualScroller': {
            borderRadius: '16px',
            backgroundColor: theme.palette.background.paper,
          },
          /*'& .MuiDataGrid-row': {
            padding: 2
          },
          '& .MuiDataGrid-cell': {
            //color: '#000', // Color del texto en el cuerpo de la tabla,
          },
          '& .MuiDataGrid-columnHeader .MuiSvgIcon-root': {
            color: '#fff', // Color personalizado
          },

          "& .MuiDataGrid-pinnedColumns": {
            display: "flex",
            flexDirection: "column"
          }*/
        }

      },
    },
  };
}