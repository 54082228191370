const getCurrency = (country: string) => {
    let currency = '';
    switch (country) {
        case 'BOLIVIA':
            currency = 'Bs ';
            break;
        case 'PERU':
            currency = 'S/ ';
            break;
        case 'BRAZIL':
        case 'BRASIL':
            currency = 'R$ ';
            break;
        default:
            currency = '';
            break;
    }

    return currency;
}
export {
    getCurrency
}