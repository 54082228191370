import { ApexOptions } from 'apexcharts';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card, { CardProps } from '@mui/material/Card';
import ListItemText from '@mui/material/ListItemText';
// utils
import { fData } from 'src/utils/format-number';
// components
import Chart, { useChart } from 'src/components/chart';
import AvatarRank from "../../components/AvatarRank";

// ----------------------------------------------------------------------

interface Props extends CardProps {
  total: number;
  goal: number;
  chart: {
    colors?: string[];
    series?: number;
    options?: ApexOptions;
  };
  data: {
    nivel: number;
    nombre: string;
    volumen_organizacional: string;
  }[];
}

export default function RankPoints({ total, goal, chart, data, ...other }: Props) {
  const theme = useTheme();
  let progressPercent = (total / goal) * 100;
  progressPercent = parseFloat(progressPercent.toFixed(2)); // Resultado: '123.46' como un string



  const { colors = [theme.palette.info.main, theme.palette.info.dark], series, options } = chart;

  const chartOptions = useChart({
    chart: {
      offsetY: -16,
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      padding: {
        top: 24,
        bottom: 24,
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          size: '56%',
        },
        dataLabels: {
          name: {
            offsetY: 8,
          },
          value: {
            offsetY: -40,
          },
          total: {
            label: `${total} PV / ${goal} PV`,
            color: theme.palette.text.disabled,
            fontSize: theme.typography.body2.fontSize as string,
            fontWeight: theme.typography.body2.fontWeight,
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          { offset: 0, color: colors[0] },
          { offset: 100, color: colors[1] },
        ],
      },
    },
    ...options,
  });

  return (
    <Card {...other}>
      <Chart type="radialBar" series={[progressPercent]} options={chartOptions} height={360} />

      <Stack spacing={1} sx={{ px: 3, pb: 5 }}>
        {data.filter((d) => parseFloat(d.volumen_organizacional) >= goal).map((category) => (
            <Stack key={category.nombre} spacing={1} direction="row" alignItems="center">
              <Box >
                <AvatarRank name={category.nombre} sx={{ width: 60, height: 60 }} />
              </Box>

              <ListItemText
                  primary={category.nombre}
                  secondary=""
                  secondaryTypographyProps={{
                    mt: 0.5,
                    component: 'span',
                    typography: 'caption',
                    color: 'text.disabled',
                  }}
              />

              <Box sx={{ typography: 'subtitle2' }}> {category.volumen_organizacional} </Box>
            </Stack>
        ))}
      </Stack>
    </Card>
  );
}
