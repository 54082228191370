import { ApexOptions } from 'apexcharts';
// @mui
import { useTheme, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card, { CardProps } from '@mui/material/Card';
import Typography from '@mui/material/Typography';
// utils
import { fCurrency, fPercent } from 'src/utils/format-number';
// theme
import { ColorSchema } from 'src/theme/palette';
import { bgGradient } from 'src/theme/css';
// components
import Iconify from 'src/components/iconify';
import Chart, { useChart } from 'src/components/chart';
import Avatar from "@mui/material/Avatar";

// ----------------------------------------------------------------------

import diamante from 'src/custom/assets/images/rank/diamante.png';
import AvatarRank from "../../../components/AvatarRank";

interface Props extends CardProps {
  rango: string;
  color?: ColorSchema;
  icon: string;
}

export default function RangoWidget({
rango,
  title,
  icon,
  color = 'primary',
  sx,
  ...other
}: Props) {
  const theme = useTheme();


  return (
    <Stack
      sx={{

        width: 1,
        borderRadius: 2,
        overflow: 'hidden',
        position: 'relative',
        color: `${color}.darker`,
        //backgroundColor: 'common.white',
          bgcolor: `#004567`,

          ...sx,
      }}
      {...other}
    >
      <Iconify
        icon={icon}
        sx={{
          p: 1.5,
          top: 24,
          right: 24,
          width: 48,
          height: 48,
          borderRadius: '50%',
          position: 'absolute',
          color: `${color}.lighter`,
          //bgcolor: `${color}.dark`,
          bgcolor: `#EFB810`,
        }}
      />

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <AvatarRank name={rango} sx={{width: 300, height: 300}}/>
        </div>
    </Stack>
  );
}
