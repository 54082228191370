/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: FilePondPxp.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      SP11AUG23       Favio Figueroa      Created
 *****************************************************************************/

import React, {useState} from 'react';
import {handleChange} from '../helpers/events';
import {StatesFormInterface} from '../types/config';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FormHelperText from "@mui/material/FormHelperText";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize);


export interface FilePondPxpComponentProps {
    nameKey: string;
    states: StatesFormInterface;
}

const areEqual = (prev: any, next: any) => {
    const nextState = next.states[next.nameKey];
    const prevState = prev.states[prev.nameKey];
    return (
        prevState.value === nextState.value &&
        prevState.config.name === nextState.config.name &&
        prevState.endAdornmentStates === nextState.endAdornmentStates &&
        prevState.disabled === nextState.disabled &&
        prevState.error === nextState.error
    );
};


function FilePondPxpComponent({states, nameKey}: FilePondPxpComponentProps) {
    const state = states[nameKey];

    const [files, setFiles] = useState([]);

    const { inputProps } = state.config;
    console.log('filepond', state.error)

    const handleProcessFile = (error:any, file:any) => {
        console.log('handleProcessFile')
        if (error) {
            state.setError({ hasError: true, msg: error.main });
            console.log('Error al procesar el archivo:', error);
        }
    };

    return (
        <span>
      <FilePond
          files={state.value}
          credits={false}
          onerror={handleProcessFile}
          onupdatefiles={(filesPond:any) => {
              handleChange({
                  e: undefined,
                  states,
                  nameKey,
                  dataValue: filesPond,
              });
          }}
          allowMultiple={state.config.allowMultiple || false}
          maxFiles={state.config.maxFiles || 1}
          //server="/api"
          name={nameKey}
          maxFileSize={'5MB'}
          labelIdle={`Drag & Drop your ${state.config.label} or <span class="filepond--label-action">Browse</span>`}
      />
        <FormHelperText>{Boolean(state.error.hasError) ? <div style={{ color: 'red' }}>{state.error.msg}</div> : state.config.helperText}</FormHelperText>


    </span>
    );
}

/**
 * A memoized component that will re-render only one of props described in areEqual change.
 */
// eslint-disable-next-line react/display-name
const FilePondPxp = React.memo(
    (props: FilePondPxpComponentProps) => <FilePondPxpComponent {...props} />,
    areEqual,
);
export default FilePondPxp;
