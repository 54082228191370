/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: PxpTable.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 25-Jul-2023      SP28JUL23       Favio Figueroa      Created
 * 18-Sep-2023      SP22SEP23       Favio Figueroa      Added react-query for getting data
 * 26-Sep-2023      SP06OCT23       Favio Figueroa      sent addParams to PxpTableHead
 * 30-Jul-2024      7124375606      Favio Figueroa      Added logic for store
 *****************************************************************************/

import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';

import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import PxpTableHead from './containers/PxpTableHead';
import PxpTableBody from './containers/PxpTableBody';
import InitButton from './hooks/InitButton';
import PxpToolBar from './containers/PxpToolBar';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';

import _ from 'lodash';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PxpForm from 'pxp-form';
import DialogActions from '@mui/material/DialogActions';
import {Button, Grid, Select} from '@mui/material';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import SkeletonLoading from './components/SkeletonLoading';
import {ActionsInToolbarInterface, ConfigTableInterface} from './types';
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import FormControl from '@mui/base/FormControl';
import Typography from "@mui/material/Typography";
import {useQuery} from "react-query";
import axios from 'axios';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import useTable from "../../hooks/use-table";


export interface PxpTableProps {
    config: ConfigTableInterface
}

const FullWidthFormControl = styled(FormControl)({
  width: '100%',
});

const PxpTable = forwardRef(({ config }: PxpTableProps, ref) => {

  const {onSetTableState, tables} = useTable();
  const prevSearchValueRef = useRef();

  const pageInitValue = 1;

  useEffect(() => {
    onSetTableState({
      nameKey:config.name, data: {
        rowsPerPage: config.pagination?.rowsPerPage || 10,
        page: pageInitValue,
        searchValue: '',
      }
    })

  }, []);

  const initialConfigFromStore = tables && tables[config.name] ? tables[config.name] : config.pagination?.rowsPerPage || 10;
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
      initialConfigFromStore.rowsPerPage || 10
  );
  const [countInPagination, setCountInPagination] = React.useState<number>(initialConfigFromStore.countInPagination || 0);

  const [searchValue, setSearchValue] = useState<any>(initialConfigFromStore.searchValue || undefined);
  const [localParams, setLocalParams] = useState<Record<any, any> | undefined>();

  //search logic
  const columnsForSearch = Object.entries(config.columns)
      // eslint-disable-next-line no-unused-vars
      .filter(([nameKey, value]) => value.search === true)
      .reduce(
          (t, [nameKey, value]) => ({...t, [nameKey]: value.filterParameterToSearch}),
          {},
      );

  const [doRequestConfig, setDoRequestConfig] = useState({
    ...config.store.getDataConfig?.doRequest,
    data: {
      ...config.store.getDataConfig?.doRequest.data,
      ...(initialConfigFromStore && typeof initialConfigFromStore === 'object' ? {
        start: parseInt(initialConfigFromStore.rowsPerPage) * (parseInt(initialConfigFromStore.page) - 1),
        ...(initialConfigFromStore.dir && initialConfigFromStore.sort && {
          dir: initialConfigFromStore.dir,
          sort: initialConfigFromStore.sort
        }),
        ...(initialConfigFromStore.searchValue && {
          genericFilterFields: Object.values(columnsForSearch).join('#'),
          genericFilterValue: initialConfigFromStore.searchValue,
          start:0,
          ...(initialConfigFromStore.rowsPerPage && {
            start: parseInt(initialConfigFromStore.rowsPerPage) * (parseInt(initialConfigFromStore.page) - 1),
          }),
          limit: rowsPerPage,
        })
      }: {}),
    }
  });


  useEffect(() => {
    console.log('effex', prevSearchValueRef)
    prevSearchValueRef.current = initialConfigFromStore.searchValue || searchValue;
  });

  const prevSearchValue = prevSearchValueRef.current;


  const getDataRemoteOrLocaly = () => {
    if(config.store.type === 'remote') {

      return config.store.getDataConfig?.instance ? config.store.getDataConfig?.instance(doRequestConfig): axios(doRequestConfig)
    } else { // it is localy
      return Promise.resolve(config.store.data);
    }
  }

  const {isLoading:loading, isLoadingError, error, isError, data, refetch}:any = useQuery(
      [config.name, doRequestConfig],
      () => getDataRemoteOrLocaly(),
      {});

  const nameCount = config.store.dataReader?.count || 'count';
  const nameDataRow = config.store.dataReader?.dataRows;

  const getDataRows = () => {
    if(config.store.type === 'remote') {
      return (nameDataRow) ? data[nameDataRow] : data;
    } else {
      const indexOfLastItem = page * rowsPerPage;
      const indexOfFirstItem = indexOfLastItem - rowsPerPage;

      const dataAux = (nameDataRow) ? data[nameDataRow] : data;
      const dataFiltered = localParams ? dataAux.filter((da:any) => {
        let found = false;
        Object.values(columnsForSearch).forEach((param:any) => {
          const lowerCaseItem = da[param].toLowerCase();
          const lowerCaseFilterValue = localParams.toLowerCase();

          if(lowerCaseItem.includes(lowerCaseFilterValue)) {
            found = true;
          }

        });
        return found;
      }) : dataAux;
      return dataFiltered && dataFiltered.slice(indexOfFirstItem, indexOfLastItem);
    }
  }

  const getData = () => {
    return data;
  }
  const addParams = (params:any, load:any) => {
    setDoRequestConfig((prev:any) => ({
      ...prev,
      data: {
        ...prev.data,
        ...params
      }
    }));
  }
  const deleteParams = (paramsToDelete:any) => {
    setDoRequestConfig((prev:any) => {
      const newStateDoRequestData = {...prev.data}; // Copy state
      paramsToDelete.forEach((param:string) => {
        delete newStateDoRequestData[param];
      });
      return {
        ...prev,
        data:{
          ...newStateDoRequestData,
        }
      }
    })
  }
  const reload = () => {
    refetch();
  }





  useEffect(() => {
    if(data && rowsPerPage) {

      const countInteger = data[nameCount] as number;
      const newCountToPagination = Math.ceil(countInteger / rowsPerPage);

      setCountInPagination(newCountToPagination)
    }
  }, [data,rowsPerPage]);



  console.log('initialConfigFromStore',initialConfigFromStore)
  //if you want to use TablePagination you need to change to 0
  const [page, setPage] = React.useState<number>(initialConfigFromStore.page || pageInitValue);

  //logic for dialog new or edit
  //we need to create logic for config form
  // @ts-ignore
  const {form, columns} = config;
  let extraAttributes;
  if(form && form.extraAttributes) {
    extraAttributes = form.extraAttributes;
  } else {
    extraAttributes = {};
  }
  let formAdd = {
    name: 'form add',
    ...form?.add,
    attributes: {},
    submit: _.mergeWith({
      onSuccess: () => {
        reload();
        setOpenDialog(false);
      }
    }, form?.add.submit)
  };
  formAdd = _.mergeWith(formAdd, form?.add );
  const formEdit = {
    name: 'form edit',
    ...form?.edit,
    attributes: {},
    submit: {
      onSuccess: () => {
        reload();
      }
    }
  };
  /*formEdit = _.mergeWith(formEdit, form.edit );*/

  Object.values(columns).filter((column) => typeof column.formAttribute === 'object').forEach((c) => {
    if (c.formAttribute?.add !== false) {
      formAdd.attributes = {
        ...formAdd.attributes,
        [c.formAttribute?.name]: c.formAttribute,
      };
    }
    if (c.formAttribute?.edit !== false) {
      formEdit.attributes = {
        ...formAdd.attributes,
        [c.formAttribute?.name]: c.formAttribute,
      };
    }
  });


  formAdd.attributes = {
    ...formAdd.attributes,
    ...Object.fromEntries(Object.entries(extraAttributes).filter(([nameKey, value]:any) => value.add !== false))
  };
  formEdit.attributes = {
    ...formAdd.attributes,
    ...Object.fromEntries(Object.entries(extraAttributes).filter(([nameKey, value]:any) => value.edit !== false))
  };



  const [editMode, setEditMode] = React.useState(false);
  const [configFormState, setConfigFormState] = React.useState(formAdd);
  const [openDialog, setOpenDialog] = React.useState(false);

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
    // end logic for dialog new or edit


  const actionsDefaultInToolBar = {
    ...((config?.defaultActionsInToolBar?.add !== false) && {
      addButton: {
        onClick: handleClickOpen,
        icon: <AddIcon color={'primary'}/>,
        label: 'new',
        disabled: false
      }
    }),
    ...((config?.defaultActionsInToolBar?.reload !== false) && {
      ReloadButton: {
        onClick: reload,
        icon: <RefreshIcon color={'primary'}/>,
        label: 'Refresh',
        disabled: false,
        ...(typeof config?.defaultActionsInToolBar?.reload === 'object' && config?.defaultActionsInToolBar?.reload?.position && {
          position: config?.defaultActionsInToolBar?.reload?.position
        })
      }
    }),
  };

  const mergeActionsInToolBar: ActionsInToolbarInterface = _.mergeWith(actionsDefaultInToolBar, config.actionsInToolBar);
  const actionsInToolBarStates = Object.entries(mergeActionsInToolBar).reduce(
    (t, [nameButton, buttonValues]) => {
      return {
        ...t,
        [nameButton]: {
          ...InitButton({button: buttonValues}),
        },
      };
    },
    {},
  );
  const actionsInCell = config.actionsInCell || {};
  const buttonsStatesInterface = Object.entries(actionsInCell).reduce(
    (t, [nameButton, buttonValues]) => {
      return {
        ...t,
        [nameButton]: {
          ...InitButton({button: buttonValues}),
        },
      };
    },
    {},
  );




  const handleInputSearchChange = _.debounce(async (value) => {

    if(config.store.type === 'remote') {
      if (value) {
        addParams({
          genericFilterFields: Object.values(columnsForSearch).join('#'),
          genericFilterValue: value,
          start:0,
          limit: rowsPerPage,
        }, true);
        setSearchValue(value)
      } else {
        deleteParams(['genericFilterFields', 'genericFilterValue']);
        setSearchValue('')
      }
    } else {
      setLocalParams(value)

    }



  }, 500);


  //end Search logic


  // logic for pagination

  const getCountPagination = () => {
    return 10
  }
  const handleChangePage = (event:any, newPage:any) => {
    if(config.store.type === 'remote') {
      addParams({
        start: rowsPerPage * (newPage - 1),
      }, true);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const rowPerPageValue = event.target.value;
    if(config.store.type === 'remote') {
      addParams({
        start: 0,
        limit: rowPerPageValue,
      }, true);
    }
    setRowsPerPage(parseInt(rowPerPageValue, 10));
    setPage(pageInitValue);

  };

  // end logic for pagination

  useImperativeHandle(ref, () => {
    return {
      loading, data, getData, addParams, deleteParams, reload
    };
  });


  useEffect(() => {
    onSetTableState({nameKey:config.name, data: {rowsPerPage }})
  }, [rowsPerPage]);

  useEffect(() => {
    onSetTableState({nameKey:config.name, data: {page }})
  }, [page]);
  useEffect(() => {
    onSetTableState({nameKey:config.name, data: {countInPagination }})
  }, [countInPagination]);
  useEffect(() => {
    if(searchValue !== undefined) {
      console.log('entra initialConfigFromStore searchValue', searchValue)
      console.log('entra initialConfigFromStore prevSearchValue', prevSearchValue)
      onSetTableState({nameKey:config.name, data: {searchValue }})
      if(prevSearchValue && searchValue !== prevSearchValue) {
        setPage(1)
      }

    }
  }, [searchValue]);


  return (
    <div className="pxp-table-container">
      <PxpToolBar
          title={config.title}
          actionsInToolBarStates={actionsInToolBarStates}
          componentsInToolBar={config.componentsInToolBar}
          handleInputSearchChange={handleInputSearchChange}
          initValue={searchValue}
      />


      <TableContainer component={Paper}>

        <Table size="small" aria-label="a dense table">
          <PxpTableHead config={config} addParams={addParams}/>
          {loading === true && <SkeletonLoading columns={columns} />}
          {loading === false && data && buttonsStatesInterface &&
                    <PxpTableBody config={config} rows={getDataRows()} buttonsStatesInterface={buttonsStatesInterface}/>}

        </Table>
        <Stack spacing={1.5}>
          {isError && (
              <Box sx={{ typography: 'body2' }}>
                <strong>ERROR</strong>
                <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
                  {JSON.stringify(error)}
                </Box>
              </Box>)}
        </Stack>
      </TableContainer>
      {data && buttonsStatesInterface && (
          // this comment will be necessary for include logic if we want to use pagination of table or another
          /*<TablePagination
          rowsPerPageOptions={config.pagination?.rowsPerPageOptions || [10]}
          component="div"
          count={data[config.store.dataReader?.count || '']}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />*/
          <Grid padding={2} marginTop={1} container spacing={2}>
            <Grid item xs={6} container justifyContent="flex-start">
              <FullWidthFormControl>
                <Typography mr={1}  sx={{display:'inline',  fontSize: '0.8em'}}>{`Showing ${(rowsPerPage * (page - 1) + 1)} to ${(rowsPerPage * (page - 1)) +rowsPerPage} of ${data[nameCount]} entries`}</Typography>
                <Select onChange={(event) => handleChangeRowsPerPage(event)} value={rowsPerPage} size="small" labelId="select-label" label="Label del Select">
                  {
                    config.pagination?.rowsPerPageOptions.map((rppo:any) => <MenuItem key={rppo} value={rppo}>{rppo}</MenuItem>)
                  }
                </Select>
              </FullWidthFormControl>
            </Grid>

            <Grid item xs={6} container justifyContent="flex-end">
              {data && countInPagination && (<Pagination color={"secondary"}
                          count={countInPagination}
                          onChange={(event, page) => handleChangePage(event,page)}
                          page={page}
                          shape="rounded"/>)}
            </Grid>
          </Grid>

      )}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Create Flow</DialogTitle>
        <DialogContent>
          <DialogContentText component={'span'}>
            <PxpForm config={configFormState}/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
                        Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default PxpTable;
