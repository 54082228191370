/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: index.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 * 10-Aug-2022      SP11AUG23       Favio Figueroa      Add layout simple for implement in iframe
 * 15-Aug-2023      HOT-1           Favio Figueroa      Redirect to simple layout when not sending path
 * 15-Oct-2022      SP20OCT23       Favio Figueroa      Added Auth Routes
 *****************************************************************************/

import { Navigate, useRoutes } from 'react-router-dom';
// config
import {PATH_HOME_PAGE} from 'src/config-global';
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { dashboardRoutes } from './dashboard';
import { simpleRoutes } from './simple';
import {authRoutes} from "./auth";

let customSimpleRoutes: any[] = [];

try {
    // eslint-disable-next-line global-require
    customSimpleRoutes = require("src/custom/routes/sections").default;
} catch (error) {
    console.error("Error import customSimpleRoutes:", error);
}

// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        // SET INDEX PAGE WITH SKIP HOME PAGE
        // {
        //   path: '/',
        //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        // },

        // ----------------------------------------------------------------------

        // SET INDEX PAGE WITH HOME PAGE
        {
          path: '/',
            element: <Navigate to={PATH_HOME_PAGE} replace />,
        },
        ...authRoutes,

        // Dashboard routes
        //...dashboardRoutes,
        ...simpleRoutes,
        ...customSimpleRoutes,
        // No match 404
        { path: '*', element: <Navigate to="not_found" replace /> },

    ] );
}
