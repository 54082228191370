import {useEffect} from "react";
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Button from "@mui/material/Button";

// _mock
import {_appFeatured, _bankingContacts, _bankingCreditCard, _bankingRecentTransitions} from 'src/custom/_mock';
// components
import { useSettingsContext } from 'src/components/settings';
//
import BankingContacts from '../banking-contacts';
import BankingQuickTransfer from '../banking-quick-transfer';
import BankingInviteFriends from '../banking-invite-friends';
import BankingWidgetSummary from '../banking-widget-summary';
import BankingCurrentBalance from '../banking-current-balance';
import BankingBalanceStatistics from '../banking-balance-statistics';
import BankingRecentTransitions from '../banking-recent-transitions';
import BankingExpensesCategories from '../banking-expenses-categories';
import AppWelcome from "../app-welcome";
import SeoIllustration from "../../../../assets/illustrations/seo-illustration";
import AppFeatured from "../app-featured";
import useApiQuery from "../../../../../hooks/use-api-query";
import {doRequest} from "../../../../../utils/axios";
import RangoWidget from "../rango-widget";

// ----------------------------------------------------------------------

export default function OverviewBankingView() {
  const theme = useTheme();

  const settings = useSettingsContext();

  const {isLoading, data}: any = useApiQuery(`getMe`,() => doRequest({
    url:'/api/afiliacion-nd/Corp/getDashboard',
    method: 'POST',
    data: {},
  }), {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      staleTime: Infinity
  })


    const {isLoading: isLoadingSlider, data: dataSlider}: any = useApiQuery(`getSlider`,() => doRequest({
        url:'/api/cms-nd/Slider/listSliderWeb',
        method: 'GET',
        data: {},
    }), {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        staleTime: Infinity,
        enabled: false
    },{
        showLoading: false
    })

  useEffect(() => {
    console.log('dataSlider',dataSlider)
  }, [dataSlider]);


  const user = 'fa'
  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Grid container spacing={3}>
        <Grid xs={12} md={8}>
          <AppWelcome
              title={`Bienvenido de nuevo 👋 ${data ? data.affiliate.nombre : '...'}`}
              description='Estamos encantados de tenerte a bordo. Aquí encontrarás todas las herramientas necesarias para tu éxito y crecimiento. Si tienes alguna pregunta o necesitas asistencia, no dudes en contactarnos. ¡Juntos alcanzaremos nuevas alturas!'
              img={<SeoIllustration />}
              action={
                <Button variant="contained" color="primary">
                  WhatsappGO
                </Button>
              }
          />
        </Grid>

        <Grid xs={12} md={4}>
            {dataSlider && (<AppFeatured list={dataSlider ? dataSlider.data : []} />)}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
          <Grid xs={12} md={5}>
              <BankingCurrentBalance list={data ? [data.affiliate] : []} />
          </Grid>
        <Grid xs={12} md={7}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <BankingWidgetSummary
              title="Ingresos"
              icon="eva:diagonal-arrow-left-down-fill"
              percent={0}
              total={data ? data.total_income_all :0}
              chart={{
                series: data ? data.payment_to_render_all : [],
              }}
            />

            <RangoWidget
              title="RANGO ACTUAL"
              color="warning"
              icon="eva:diagonal-arrow-right-up-fill"
              rango={data ? data.affiliate.rango : ''}
            />
          </Stack>
        </Grid>



        <Grid xs={12} md={8}>
          <Stack spacing={3}>
            <BankingBalanceStatistics
              title="Evolución de Ingresos Mensuales: [Mes Inicial] a [Mes Final]"
              subheader="El 'Total Ganado' incluye ya sumado el 'Total Red'"
              chart={{
                categories: data ? data.months : [],
                series: [
                  {
                    type: 'Month',
                    data: [
                      { name: 'Total Ganado', data: data ? data.total_earned : [] },
                      { name: 'Red', data: data ? data.total_red : [] },
                    ],
                  },
                ],
              }}
            />

            <BankingExpensesCategories
              title="Afiliados por Nivel"
              chart={{
                series: data ? data.count_by_level : [],
                colors: [
                  theme.palette.primary.main,
                  theme.palette.warning.dark,
                  theme.palette.success.darker,
                  theme.palette.error.main,
                  theme.palette.info.dark,
                  theme.palette.info.darker,
                  theme.palette.success.main,
                  theme.palette.warning.main,
                  theme.palette.info.main,
                ],
              }}
            />

            <BankingRecentTransitions
              title="Mis Directos"
              tableData={data ? data.affiliates_first_level : []}
              tableLabels={[
                { id: 'affiliate', label: 'Afiliado' },
                { id: 'date', label: 'Fecha Reg' },
                { id: 'amount', label: 'Amount' },
                { id: 'status', label: 'Status' },
              ]}
            />
          </Stack>
        </Grid>

        <Grid xs={12} md={4}>
          <Stack spacing={3}>
            {/*<BankingQuickTransfer title="Quick Transfer" list={_bankingContacts} />*/}

            <BankingContacts
              title="Rangos "
              subheader="total de los rangos del 1 nivel"
              list={data ? data.count_by_range_first_level : []}
            />

            <BankingInviteFriends
              price="💰"
              title={`Invitar a amigos \n y gana`}
              description="Invita a que se registren bajo tu código"
            />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
