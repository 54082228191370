/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: root-reducer.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 * 21-AUG-2022      SP25AUG23       Favio Figueroa      Add reducer for flow
 * 12-SEP-2022      SP22SEP23       Favio Figueroa      Add reducer for loading
 * 30-Jul-2024      7124375606      Favio Figueroa      Add reducer for table
 *****************************************************************************/

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import checkoutReducer from './slices/checkout';
import commonReducer from './slices/common';
import tableReducer from './slices/table';

let customReducer:any;
try {
  // eslint-disable-next-line global-require
  customReducer = require("src/custom/redux/slices").reducers;
} catch (error) {
  console.error("Error import customReducer:", error);
}
// ----------------------------------------------------------------------

const checkoutPersistConfig = {
  key: 'checkout',
  storage,
  keyPrefix: 'redux-',
};


export const rootReducer = combineReducers({
  checkout: persistReducer(checkoutPersistConfig, checkoutReducer),
  common: commonReducer,
  table: tableReducer,
  ...(customReducer && customReducer)
});
