import React, {useEffect} from 'react';
import ListItemText from "@mui/material/ListItemText";
import PxpTable, {ConfigTableInterface} from "../../../lib/pxpTable";
import Label from "../../../components/label";
import {doRequest} from "../../../utils/axios";
import useApiQuery from "../../../hooks/use-api-query";

interface Interface {
    dataDetail: any;
    level: any;
    periodoId: any;
    code?: any;
}
const LevelDetail = ({dataDetail, level, periodoId, code}: Interface) => {
    const {isLoading, data}: any = useApiQuery([periodoId, code],() => doRequest({
        url:'/api/afiliacion-nd/Corp/getDetailRedPersonPaymentPeriod',
        method: 'GET',
        data: {
            id_periodo: periodoId,
            ...(code && {code})
        },
    }), {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        staleTime: Infinity
    })


    const dataToRender = data ? data.filter((dd:any) => dd.nivel === level ) : []
    const tableConfig: ConfigTableInterface = {
        name: 'detailTable',
        title:'',
        columns: {
            nivel: {
                label: 'nivel',
            },
            codigo: {
                label: 'Codigo',
                search: true,
                filterParameterToSearch: 'codigo',
                renderColumn: ({ row }) => (
                    <ListItemText
                        primary={row.codigo}
                        secondary={row.nombre_completo_afiliado}
                        primaryTypographyProps={{ typography: 'body2' }}
                        secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
                    />
                ),

            },
            codigo_patrocinador: {
                label: 'Patrocinador',
                search: true,
                filterParameterToSearch: 'codigo_patrocinador',
                renderColumn: ({ row }) => (
                    <ListItemText
                        primary={row.codigo_patrocinador}
                        secondary={row.nombre_completo_patrocinador}
                        primaryTypographyProps={{ typography: 'body2' }}
                        secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
                    />
                ),

            },
            sum_cv: {
                label: 'CV Total',
                search: true,
                filterParameterToSearch: 'nombre_completo_afiliado',

            },
            sum_pv: {
                label: 'PV Total',
                search: true,
                filterParameterToSearch: 'nombre_completo_patrocinador',

            },
        },
        pagination: {
            rowsPerPageOptions: [10, 20, 30],
            rowsPerPage: 10,
        },
        store: {
            type: 'local',
            data: {
                data: dataToRender,
                count: dataToRender.length,
            },
            dataReader: {
                dataRows: 'data',
                count: 'count'
            },
            storeId: 'id'
        },
        filterConfig: {
            filterName: 'genericFilterFields',
            filterValue: 'genericFilterValue',
        },
        defaultActionsInToolBar: {
            add: false,
        },
        actionsInToolBar: false,
        actionsInCell:false,
    };
    return (
        <div>
            {!isLoading && data && (<PxpTable config={tableConfig} />)}

        </div>
    );
};

export default LevelDetail;