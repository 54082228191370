/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: useJsonStore.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      SP11AUG23       Favio Figueroa      Created
 * 19-SEP-2023      SP22SEP23       Favio Figueroa      Remove useFetch and use react-query for getting data
 *****************************************************************************/

import {useEffect, useState} from 'react';
import { StoreAttributeInterface } from '../types/config';
import {useInfiniteQuery, useQuery} from "react-query";
import axios from "axios";
const ensureArray = (value:Record<any, any>[] | Record<any, any>) => Array.isArray(value) ? value : [value];

const useJsonStore = (optionsRequest :StoreAttributeInterface) => {

  const [requestState, setRequestState] = useState( optionsRequest);

  const {isLoading:loading, error, isError, data,
      fetchNextPage,
      isFetchingNextPage,
      hasNextPage
  }:any  = useInfiniteQuery(
      ['ID_COMPOEN',requestState],
      ({pageParam = requestState.axios.config}) => optionsRequest.axios.instance ? requestState.axios.instance(pageParam): axios(pageParam),
      {
        enabled: requestState.load,
        getNextPageParam: (lastPage, allPages) => {
          const {limit } = requestState.axios.config.data;
          const nextOffset = allPages.length * limit;
          if(optionsRequest.dataReader.count && lastPage.count >= nextOffset) {
            return {
              ...requestState.axios.config,
              data: {
                ...requestState.axios.config.data,
                start: nextOffset
              }
            }

          }
          return undefined

        }
      });


  const getDataFromDataRowName = () => {
    const datos = data?.pages?.flatMap((page:any) => (requestState.dataReader.dataRows) ? page[requestState.dataReader.dataRows] :page.data) || [];
    //return (dataRows) ? dataStore?.data[dataRows] : dataStore?.data;
    return ensureArray(datos);
  }

  const fetchNextPageData = () => {
    console.log('fetchNextPageData hasNextPage',hasNextPage)
    console.log('fetchNextPageData isFetchingNextPage',isFetchingNextPage)
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }


  const addParams = (params: Record<any, any>, load = true) => {
    setRequestState((prev:any) => (
        {
          ...prev,
          load: load,
          axios: {
            ...prev.axios,
            config: {
              ...prev.axios.config,
              data: {
                ...prev.axios.config.data,
                ...params
              }
            }
          }
        }
    ))
  };
  const deleteParams = (paramsToDelete:any) => {
    setRequestState((prev:any) => {
      const newStateDoRequestData = {...prev.axios.config.data}; // Copy state
      paramsToDelete.forEach((param:string) => {
        delete newStateDoRequestData[param];
      });
      return {
        ...prev,
        load: true,
        axios: {
          ...prev.axios,
          config: {
            ...prev.axios.config,
            data: {
              ...newStateDoRequestData
            }
          }
        }
      }
    })
  }

  const cleanParams = ({idDD} :any) => {
    setRequestState((prev:any) => {
      const cleanData = {...prev.axios.config.data}; // Copy state
      delete cleanData[idDD];
      return {
        ...prev,
        load: true,
        axios: {
          ...prev.axios,
          config: {
            ...prev.axios.config,
            data: {
              ...cleanData
            }
          }
        }
      }
    })


  }


  const getData = () => {
    return data;
  };

  return {
    requestState,
    setRequestState,
    loading,
    error,
    data,
    storeConfig:optionsRequest,
    addParams,
    deleteParams,
    cleanParams,
    getData,
    fetchNextPageData,
    getDataFromDataRowName,
  };
};

export default useJsonStore;
