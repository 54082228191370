/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: use-auth-context.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 *****************************************************************************/

import { useContext } from 'react';
//
import { AuthContext } from '../context/jwt/auth-context';
//import { AuthContext } from '../context/firebase/auth-context';

// ----------------------------------------------------------------------

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  console.log('context',context)

  if (!context) throw new Error('useAuthContext context must be use inside AuthProvider');

  return context;
};
