/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: useFormPxp.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      SP11AUG23       Favio Figueroa      Created
 * 19-SEP-2023      SP22SEP23       Favio Figueroa      Changed logic for initial value in autocomplete
 * 13-NOV-2023      MAIN            Favio Figueroa      Added logic for AutoCompleteMultiple
 * 30-NOV-2023      SP24NOV23       Favio Figueroa      Added logic for picker
 *****************************************************************************/

import {useEffect, useRef, useState} from 'react';
import useJsonStore from './useJsonStore';
import * as Yup from 'yup';
import {AttributeInterface} from '../types/config';
import useAdornment from './useAdornment';

export interface useFormPxpProps {
    nameKey: string;
    configuredAttribute: AttributeInterface;
}

export const useFormPxp = ({ nameKey, configuredAttribute }:useFormPxpProps) => {
  const {type, initialValue, validate, inputProps, metadata: md} = configuredAttribute;
  let { store } = configuredAttribute;
  let valueField = initialValue;
  if(type === 'AutoComplete' && typeof initialValue === 'number') {
    valueField = undefined;
  }

  if (!valueField) {
    switch (type) {
      case 'AutoComplete':
        // @ts-ignore
        valueField = null;
        break;
      case 'AutoCompleteMultiple':
        // @ts-ignore
        valueField = [];
        break;
      case 'Switches':
        // @ts-ignore
        valueField = [];
        break;
      case 'DatePicker':
        // @ts-ignore
        valueField = null;
        break;
      case 'TimePicker':
        // @ts-ignore
        valueField = null;
        break;
      case 'Switch':
        valueField = false;
        break;
        /*case 'DatePicker':
            case 'TimePicker':
                valueField = null;
                break;
            case 'Switch':
                valueField = false;
                break;
            case 'DropzoneArea':
                valueField = [];
                break;*/
      default:
        valueField = '';
        break;
    }
  }






  const [value, setValue] = useState(valueField);
  const [error, setError] = useState({ hasError: false, msg: '' });
  const [disabled, setDisabled] = useState(configuredAttribute.disabled || false);
  const [hidden, setHidden] = useState(configuredAttribute.hidden || false);
  const [open, setOpen] = useState(false);
  const [yupValidate, setYupValidate] = useState(validate);
  const [metadata, setMetadata] = useState(md);
  const [initDefaultValueFromStore, setInitDefaultValueFromStore] = useState(false);



  let endAdornmentStates = null;
  if(inputProps && inputProps.endAdornment && typeof inputProps.endAdornment === 'object' ) {
    endAdornmentStates = {};
    for (const endAdornmentFor of Object.entries(inputProps.endAdornment)) {
      const [nameKeyAdornment, adornment] = endAdornmentFor;
      // @ts-ignore
      endAdornmentStates[nameKeyAdornment] = useAdornment({nameKeyAdornment, adornment});
    }

  }


  const inputRef = useRef<any>();

  const disable = () => setDisabled(true);
  const enable = () => setDisabled(false);
  const hide = () => setHidden(true);
  const show = () => setHidden(false);
  const reset = () => {
    switch (type) {
      case 'AutoComplete':
        // @ts-ignore
        setValue(null);
        break;
      case 'AutoCompleteMultiple':
        // @ts-ignore
        setValue([]);
        break;
        case 'DatePicker':
                // @ts-ignore
                setValue(null);
                break;
        case 'TimePicker':
                // @ts-ignore
                setValue(null);
                break;
        case 'MobileTimePicker':
                // @ts-ignore
                setValue(null);
                break;
        case 'GoogleReCaptchaV2':
              inputRef.current && inputRef.current.reset();
              // @ts-ignore
              setValue(null);
              break;
        case 'FilePond':
              setValue([]);
              break;
        case 'Switch':
            // @ts-ignore
            setValue(false);
            break;
        /* case 'DropzoneArea':
            // @ts-ignore
            setValue([]);
            break;*/
      default:
        setValue('');
        break;
    }
    setError({ hasError: false, msg: '' });
  };



  // listen if the value of autocomplete  is changed
  useEffect(() => {
    if (type === 'AutoComplete') {
      if (value != null) {
        setError({ hasError: false, msg: '' });
      }
    }
    if (type === 'AutoCompleteMultiple') {
      if (value != null) {
        setError({ hasError: false, msg: '' });
      }
    }

    if (type === 'DatePicker') {
      if (value != null) {
        setError({ hasError: false, msg: '' });
      }
    }
    if (type === 'TimePicker') {
      if (value != null) {
        setError({ hasError: false, msg: '' });
      }
    }

    if (type === 'FilePond') {
      if (value != null) {
        setError({ hasError: false, msg: '' });
      }
    }
  }, [value]);


  if(type === 'AutoComplete' && store && !initDefaultValueFromStore && initialValue && typeof initialValue === 'number' && value === null) {
    store = {
      ...store,
      load: true,
      axios: {
        ...store.axios,
        config: {
          ...store.axios.config,
          data: {
            ...store.axios.config.data,
            [store.idDD]: initialValue,
          }
        }
      }
    };
  }
  let configStore:Record<any, any>= {};

  if ((type === 'AutoComplete' || type === 'AutoCompleteMultiple') && store) {
    configStore = {
      ...configStore,
      dataStore: useJsonStore(store)
    };
  }

  useEffect(() => {

    if(store && configStore.dataStore && configStore.dataStore.data) {
      if(!initDefaultValueFromStore && type === 'AutoComplete' && initialValue && typeof initialValue === 'number' && value === null) {
        const { data } = configStore.dataStore;
        const dataRows = store.dataReader.dataRows;
        const dataToInitialValue = configStore.dataStore?.data.pages.flatMap((page:any) => (dataRows) ? page[dataRows] :page.data) || [];
        if(Array.isArray(dataToInitialValue)) {
          setValue(dataToInitialValue[0]);
        } else {
          setValue(dataToInitialValue);
        }
        setInitDefaultValueFromStore(true);
      }
    }
  }, [configStore]);


  useEffect(() => {
    if(inputRef && inputRef.current && yupValidate) {
      const schemaOnlyField = Yup.object().shape({
        [nameKey]: yupValidate.shape,
      });
      schemaOnlyField.validateAt(nameKey, { [nameKey]: value })
        .then(() => {
          setError({ hasError: false, msg: '' });
        })
        .catch((err) => {
          setError({ hasError: true, msg: err.message });
        });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      //inputRef && inputRef.current && inputRef.current.blur();
    }
  }, [yupValidate]);


  useEffect(() => {
    if(configuredAttribute.valueChange) {
      configuredAttribute.valueChange({value:value});
    }
  }, [value]);

  return {
    nameKey,
    value: value,
    setValue: setValue,
    error,
    setError,
    disabled,
    setDisabled,
    hidden,
    setHidden,
    open,
    setOpen,
    yupValidate,
    setYupValidate,
    inputRef,
    disable,
    enable,
    hide,
    show,
    reset,
    config: configuredAttribute,
    ...configStore,
    endAdornmentStates,
    metadata,
    setMetadata
  };
};
