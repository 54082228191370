/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: DatePickerPxp.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      SP11AUG23       Favio Figueroa      Created
 * 29r-Nov-2023      SP24NOV23       Favio Figueroa      Change all logic for datepicker
 *****************************************************************************/

import React from 'react';
import TextField from '@mui/material/TextField';
import {handleChange} from '../helpers/events';
import {StatesFormInterface} from '../types/config';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';


export interface DatePickerPxpComponentProps {
    nameKey: string;
    states: StatesFormInterface;
}

const areEqual = (prev: any, next: any) => {
  const nextState = next.states[next.nameKey];
  const prevState = prev.states[prev.nameKey];

    return (
    prevState.value === nextState.value &&
        prevState.config.name === nextState.config.name &&
        prevState.config.originalProps === nextState.config.originalProps &&
        prevState.error.hasError === nextState.error.hasError &&
        prevState.endAdornmentStates === nextState.endAdornmentStates &&
        prevState.error === nextState.error

);
};


function TimePickerPxpComponent({states, nameKey}: DatePickerPxpComponentProps) {
  const state = states[nameKey];
    const [value, setValue] = React.useState<Date | null>(new Date());

    console.log('TimePickerPxpComponent state.value',state.value)


  const {inputProps} = state.config;
  return (
    //<LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label={state.config.label}
        value={state.value}
        disabled={state.disabled}
        onChange={(newValue:any, picker:any) => {
          console.log('newValue',newValue)
          handleChange({
            e: undefined,
            states,
            nameKey,
            dataValue: newValue,
          });
        }}
        slotProps={{
          textField: {
            fullWidth: true,
            helperText: state.error.hasError ? state.error.msg : state.config.helperText,
            error: Boolean(state.error.hasError),
            variant: state.config.variant,
            size: state.config.size
          },
        }}
        {...(state.config.originalProps && state.config.originalProps)}





      />
    //</LocalizationProvider>
  );
}

/**
 * A memoized component that will re-render only one of props described in areEqual change.
 */
// eslint-disable-next-line react/display-name
const TimePickerPxp = React.memo(
  (props: DatePickerPxpComponentProps) => <TimePickerPxpComponent {...props} />,
  areEqual,
);
export default TimePickerPxp;
