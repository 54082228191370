/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: use-mocked-user.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 *****************************************************************************/

import ffp from 'src/assets/images/faviofigueroa.jpg';
// TO GET THE USER FROM THE AUTHCONTEXT, YOU CAN USE

// CHANGE:
// import { useMockedUser } from 'src/hooks/use-mocked-user';
// const { user } = useMockedUser();

// TO:
 import { useAuthContext } from 'src/auth/hooks';
// const { user } = useAuthContext();

// ----------------------------------------------------------------------

export function useMockedUser() {
  const { user } = useAuthContext();
  //console.log('user',user)

  /*const user = {
    id: '8864c717-587d-472a-929a-8e5f298024da-0',
    displayName: 'Favio Figueroa',
    email: 'favio@effex.io',
    password: 'tempoui',
    photoURL: ffp,
    phoneNumber: '+59170779285',
    country: 'United States',
    address: '33101',
    state: 'COCHABAMBA',
    city: 'cercado',
    zipCode: '123',
    about: '123123123',
    role: 'admin',
    isPublic: true,
  };*/

  return { user };
}
