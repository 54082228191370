/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: config-layout.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 *****************************************************************************/

// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: 80,
  H_DESKTOP_OFFSET: 80 - 16,
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};
