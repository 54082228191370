/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: use-router.ts
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION:
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-Jul-2022      SP14JUL23       Favio Figueroa      Created
 * 15-Aug-2023      HOT-1           Favio Figueroa      Add getCurrentRoute and to for keeping current layout
 * 18-Aug-2023      SP25AUG23       Favio Figueroa      Add options in "to" function
 *****************************************************************************/

import { useMemo } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

// ----------------------------------------------------------------------

export function useRouter() {
  const navigate = useNavigate();
  const location = useLocation();

  const router = useMemo(
    () => ({
      back: () => navigate(-1),
      forward: () => navigate(1),
      reload: () => window.location.reload(),
      push: (href: string) => navigate(href),
      replace: (href: string) => navigate(href, { replace: true }),
      getCurrentRoute: () => location.pathname,
      to: (href: string, options:any = undefined) => {
          const currentPath = router.getCurrentRoute();
          const layout = currentPath.split('/')[1];
          navigate(`/${layout}${href}`,options)
      }

    }),
    [navigate, location]
  );

  return router;
}
