// @mui
import Box from '@mui/material/Box';
import Card, { CardProps } from '@mui/material/Card';
import Label from 'src/components/label';
// utils
import { fShortenNumber } from 'src/utils/format-number';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title: string;
  description?: string;
  total: number;
  //icon: React.ReactElement;
  icon: any;
  label?: string;
}

export default function WidgetSummary({ title, description, total, icon, label, sx, ...other }: Props) {
  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        pl: 3,
        ...sx,
      }}
      {...other}
    >
      <Box>
        {/*<Box sx={{ mb: 1, typography: 'h3' }}>{fShortenNumber(total)}</Box>*/}
        <Box sx={{ mb: 1, typography: 'h3' }}>
            {total}
            {
                label && (
                    <Label
                        variant="filled"
                        color="error"
                        sx={{
                            position: 'absolute'
                        }}
                    >
                        {label}
                    </Label>
                )
            }

        </Box>
        <Box sx={{ color: 'text.secondary', typography: 'subtitle2' }}>{title}</Box>
        <Box sx={{ color: 'text.secondary', typography: 'subtitle2' }}>{description}</Box>

      </Box>

      <Box
        sx={{
          width: 80,
          height: 80,
          lineHeight: 0,
          borderRadius: '50%',
          bgcolor: 'background.neutral',
        }}
      >
        {icon}
      </Box>
    </Card>
  );
}
