/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: FilePondPxp.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      SP11AUG23       Favio Figueroa      Created
 *****************************************************************************/

import React, {useState} from 'react';
import {handleChange} from '../helpers/events';
import {StatesFormInterface} from '../types/config';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ButtonBase from "@mui/material/ButtonBase";
import MenuItem from "@mui/material/MenuItem";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


export interface PaperButtonsPxpComponentProps {
    nameKey: string;
    states: StatesFormInterface;
}

const areEqual = (prev: any, next: any) => {
    const nextState = next.states[next.nameKey];
    const prevState = prev.states[prev.nameKey];
    return (
        prevState.value === nextState.value &&
        prevState.config.name === nextState.config.name &&
        prevState.config.options === nextState.config.options &&
        prevState.endAdornmentStates === nextState.endAdornmentStates &&
        prevState.disabled === nextState.disabled &&
        prevState.error === nextState.error
    );
};

function PaperButtonsPxpComponent({states, nameKey}: PaperButtonsPxpComponentProps) {
    const state = states[nameKey];

    console.log('PaperButtons',state)


    const { inputProps, options } = state.config;


    const handleClick = (e:any, item: any) => {
        if (state.config.allowMultiple) {
            const newValue = state.value.includes(item)
              ? state.value.filter((i: any) => i !== item)
              : [...state.value, item];
            handleChange({e: null, states, nameKey, dataValue: newValue});
        } else {
            handleChange({e: null, states, nameKey, dataValue: item});
        }
    }

    return (
        <>
            <Stack spacing={1}>
                {
                    state.config.label && (
                    <Typography variant="subtitle2">{state.config.label}</Typography>
                  )
                }
                {state.config.helperText && (
                  <Typography variant="caption">{state.config.helperText}</Typography>
                )}
            </Stack>
            {/*<Box gap={2} display="grid" gridTemplateColumns="repeat(1, 1fr)">*/}
            <Box gap={2} display="grid" gridTemplateColumns={state.config.gridTemplateColumns || 'repeat(1, 1fr)'}>
                {state.config.type === 'PaperButtons' && Array.isArray(state.config.options!) && state.config.options!.map((item:any) => {

                    const isSelected = state.config.allowMultiple
                      ? state.value.includes(item)
                      : item[state.config.valueName || 'value'] === state.value[state.config.valueName || 'value'];

                    return (
                            <Paper
                                component={ButtonBase}
                                variant="outlined"
                                key={item.value}
                                onClick={(e:any) => handleClick(e,item)}
                                //onClick={e => handleChange({e, states, nameKey, dataValue: item})}
                                sx={{
                                    //p: 2.5,
                                    borderRadius: 2,
                                    typography: 'subtitle2',
                                    flexDirection: 'column',
                                ...(isSelected && {
                                        borderWidth: 2,
                                        borderColor: 'text.primary',
                                    }),
                                }}
                            >
                                {
                                    state.config.component ? (
                                    <state.config.component data={item} selected={isSelected}/>
                                    ): (
                                        <>
                                            {item.icon}
                                            {item.label}
                                        </>
                                    )
                                }

                            </Paper>
                        )

                })}
            </Box>

    </>
    );
}

/**
 * A memoized component that will re-render only one of props described in areEqual change.
 */
// eslint-disable-next-line react/display-name
const PaperButtonsPxp = React.memo(
    (props: PaperButtonsPxpComponentProps) => <PaperButtonsPxpComponent {...props} />,
    areEqual,
);
export default PaperButtonsPxp;
