/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: SelectPxp.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 25-AUG-2023      SP25AUG23       Favio Figueroa      Created
 *****************************************************************************/

// @ts-nocheck
import React from 'react';
import Select  from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import {handleChange, handleBlur} from '../helpers/events';
import {StatesFormInterface} from '../types/config';

export interface SelectPxpComponentProps {
    nameKey: string;
    states: StatesFormInterface;
}

const areEqual = (prev: any, next: any) => {
  const nextState = next.states[next.nameKey];
  const prevState = prev.states[prev.nameKey];
  return (
    prevState.value === nextState.value &&
        prevState.config.name === nextState.config.name &&
        prevState.endAdornmentStates === nextState.endAdornmentStates &&
        prevState.disabled === nextState.disabled &&
        prevState.error === nextState.error
  );
};


function SelectPxpComponent({states, nameKey}: SelectPxpComponentProps) {
  const state = states[nameKey];
  const { inputProps } = state.config;
  console.log('state',state)
    return (
        <FormControl fullWidth>
            <InputLabel size={state.config.size}  id={`${state.config.name}-select-label`}>{state.config.label}</InputLabel>
            <Select
                labelId={`${state.config.name}-select-label`}
                id={state.config.name}
                value={state.value}
                inputRef={state.inputRef}
                label={state.config.label}
                onChange={e => handleChange({e, states, nameKey})}
                onBlur={() => handleBlur(state)}
                disabled={state.disabled}
                error={Boolean(state.error.hasError)}
                fullWidth
                name={nameKey}
                variant={state.config.variant}
                size={state.config.size}
            >
                {state.config.type === 'Dropdown' && Array.isArray(state.config.options!) && state.config.options!.map((opt, indexOpt) => (
                    <MenuItem key={indexOpt} value={opt.value}>{opt.label}</MenuItem>
                ))}
            </Select>
            <FormHelperText>{state.error.hasError ? state.error.msg : state.config.helperText}</FormHelperText>
        </FormControl>
  );
}

/**
 * A memoized component that will re-render only one of props described in areEqual change.
 */
// eslint-disable-next-line react/display-name
const SelectPxp = React.memo(
  (props: SelectPxpComponentProps) => <SelectPxpComponent {...props} />,
  areEqual,
);
export default SelectPxp;
