import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Card, { CardProps } from '@mui/material/Card';

import {fCurrency, fShortenNumber} from 'src/utils/format-number';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {getCurrency} from "../../helpers/helpers";

// ----------------------------------------------------------------------

type ItemProps = {
  id: string;
  name: string;
  android: number;
  windows: number;
  apple: number;
  flag: string;
};

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  data: any;
}

export default function CommissionByCountries({ title, subheader, data, ...other }: Props) {

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3 }}>

            <Stack direction="row" alignItems="center" spacing={2}>
                <Stack direction="row" alignItems="center" flexGrow={1} sx={{ minWidth: 120 }}>
                    <Typography variant="subtitle2" noWrap>
                        Regalias por País:
                    </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" sx={{ minWidth: 80 }}>
                    <Typography variant="body2">Total Red</Typography>
                </Stack>

                <Stack direction="row" alignItems="center" sx={{ minWidth: 80 }}>
                    <Typography variant="body2">Bonus Patrocinio</Typography>
                </Stack>

                <Stack direction="row" alignItems="center" sx={{ minWidth: 80 }}>
                    <Typography variant="body2">Total País</Typography>
                </Stack>
            </Stack>
            {
                data && data.map((d:any, index:number) => (
                    <CountryItem key={index} data={d} />
                ))
            }
          {/*{list.map((country) => (
            <CountryItem key={country.id} country={country} />
          ))}*/}
        </Stack>
      </Scrollbar>
    </Card>
  );
}

// ----------------------------------------------------------------------

/*type CountryItemProps = {
  data: {
      total_red?: number
      total_bono?: number
  };
};*/
const flags = {
    BOLIVIA: 'emojione-v1:flag-for-bolivia',
    BRASIL: 'emojione-v1:flag-for-brazil',
    PERU: 'emojione-v1:flag-for-peru',
};

function CountryItem({ data }: any) {
    const {total_red =0, total_bono = 0} = data;

    console.log('CountryItem data', data)
    const total = total_red + total_bono;
    // @ts-ignore
    const flag = flags[data.pais];

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Stack direction="row" alignItems="center" flexGrow={1} sx={{ minWidth: 120 }}>
        <Iconify icon={flag} sx={{ borderRadius: 0.65, width: 28, mr: 1 }} />

        <Typography variant="subtitle2" noWrap>
            {data.pais}
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" sx={{ minWidth: 80 }}>
        <Iconify
          width={14}
          icon="healthicons:money-bag"
          sx={{ mr: 0.5, color: 'text.disabled' }}
        />
        <Typography variant="body2">{data.total_red ? data.total_red : 0}</Typography>
      </Stack>

      <Stack direction="row" alignItems="center" sx={{ minWidth: 80 }}>
        <Iconify icon="healthicons:money-bag" width={14} sx={{ mr: 0.5, color: 'text.disabled' }} />
        <Typography variant="body2">{data.total_bono ? data.total_bono : 0}</Typography>
      </Stack>

      <Stack direction="row" alignItems="center" sx={{ minWidth: 80 }}>
        <Typography variant="body2">{fCurrency(total, getCurrency(data.pais))}</Typography>
      </Stack>
    </Stack>
  );
}
