/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: PxpToolBar.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 25-Jul-2023      SP28JUL23       Favio Figueroa      Created
 * 30-Jul-2024      7124375606      Favio Figueroa      Added logic for store
 *****************************************************************************/

import React, {useState} from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import InputBase from '@mui/material/InputBase';
import { alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import {ToggleButton, Tooltip} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {ComponentsInToolbarInterface} from "../types";




const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
  border: '1px solid #B3B3B3'
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));



interface PxpToolBarProps {
  title?: string;
  actionsInToolBarStates: Record<any, any>;
    componentsInToolBar: ComponentsInToolbarInterface | undefined | false;
    handleInputSearchChange: any;
    initValue?: string;
  numSelected?: number;
  sx?: Record<any, any>;
}

const PxpToolBar = ({title = '', actionsInToolBarStates, componentsInToolBar, handleInputSearchChange, initValue = '', numSelected = 0, sx}: PxpToolBarProps) => {

    const [searchValue, setSearchValue] = useState<any>(initValue);
    const handleChange = (value:any) => {
        console.log(value)
        setSearchValue(value)
        handleInputSearchChange(value);
    }


    return (<Toolbar
        sx={{
          pl: { xs: 0, sm: 0 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
           bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
         ...sx,
          //bgcolor: (theme) => '#F9F2FF' // this we need to remove and add style from option

        }}
    >

      {numSelected > 0 ? (
          <Typography
              sx={{ flex: '1 1 100%' }}
              color="inherit"
              variant="subtitle1"
              component="div"
          >
            {numSelected} selected
          </Typography>
      ) : (
          <>
          {
            Object.entries(actionsInToolBarStates).filter(([,buttonToFilter]) => buttonToFilter.position === 'left').map(([nameKey,values]) => {
              let ButtonComponent; // Copy state
              if(values.button ) {
                ButtonComponent = React.cloneElement(values.button, {
                  onClick: ()=> {values.onClick();},
                  sx: {
                    margin: 1
                  },
                  disabled: values.disabled,
                  key: nameKey
                });

              } else {
                ButtonComponent = <Tooltip title={values.label} key={`tooltip_${nameKey}`}>
                    <ToggleButton
                        style={{backgroundColor: '#FFF', marginRight: '10px'}}
                        color='secondary'
                        key={nameKey}
                        size='small'
                        value={values.label}
                        aria-label={values.label}
                        disabled={values.disabled}
                        onClick={() => values.onClick()}
                    >
                        {values.icon}
                    </ToggleButton>
                    </Tooltip>;
              }
              return (ButtonComponent)
            })
          }
          {
              componentsInToolBar && Object.entries(componentsInToolBar).filter(([,componentToFilter]) => componentToFilter.position === 'left').map(([nameKey,values]) => {
                  return (values.component)
              })

          }

          <Typography
              sx={{ flex: '1 1 100%' }}
              variant="h6"
              id="tableTitle"
              component="div"
          >
            {title}
          </Typography>
          </>
      )}



      {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
      ) : (
          <>

            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                  value={searchValue}
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={(e) => handleChange(e.target.value)}
              />
            </Search>

            {Object.entries(actionsInToolBarStates).filter(([,buttonToFilter]) => buttonToFilter.position === 'right' || !buttonToFilter.position).map(([nameKey,values]) => {
              let ButtonComponent; // Copy state
              if(values.button ) {
                ButtonComponent = React.cloneElement(values.button, {
                  onClick: ()=> {values.onClick();},
                  sx: {
                    margin: 1
                  },
                  disabled: values.disabled
                });

              } else {
                /*ButtonComponent = <PxpButton
                  key={nameKey}
                  title={values.label}
                  icon={values.icon}
                  onClick={() => values.onClick()}
                  disabled={values.disabled}
                />;*/
                ButtonComponent = <Tooltip title={values.label}  key={`tooltip_${nameKey}`}>
                    <ToggleButton
                        style={{backgroundColor: '#FFF', marginLeft: '10px'}}
                        color='secondary'
                        key={nameKey}
                        size='small'
                        value={values.label}
                        aria-label={values.label}
                        disabled={values.disabled}
                        onClick={() => values.onClick()}
                    >
                        {values.icon}
                    </ToggleButton>
                </Tooltip>;
              }

              return (
                  ButtonComponent
              );
            })}

              {
                  componentsInToolBar && Object.entries(componentsInToolBar).filter(([,componentToFilter]) => componentToFilter.position === 'right').map(([nameKey,values]) => {
                      return (values.component)
                  })

              }
          </>
      )}
    </Toolbar>
)};
export default PxpToolBar;

