/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: simple.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 10-Aug-2022      SP11AUG23       Favio Figueroa      Created
 * 15-Aug-2023      HOT-1           Favio Figueroa      adding flowList when it does not have path
 * 22-Aug-2023      SP25AUG23       Favio Figueroa      removed unused variables
 * 12-Sep-2023      SP22SEP23       Favio Figueroa      Add Common component for global components
 * 15-Oct-2022      SP20OCT23       Favio Figueroa      Added AuthGuard
 *****************************************************************************/

import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// components
import {SplashScreen} from 'src/components/loading-screen';
import SimpleLayout from 'src/layouts/simple';
import Common from "../../../components/common/Common";

// ----------------------------------------------------------------------
const StepperPage = lazy(() => import('src/pages/components/mui/stepper'));

// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
const PreRegisterPage = lazy(() => import('src/custom/pages/public/PreRegister'));
// ----------------------------------------------------------------------

export const publicRoutes = [
  {
    path: 'public',
    element: (
            <>
                <Common />
                <SimpleLayout>
                    <Suspense fallback={<SplashScreen />}>
                        <Outlet />
                    </Suspense>
                </SimpleLayout>
            </>
    ),
    children: [
      // { element: <IndexPage />, index: true },
      { element: <BlankPage />, index: true },

        { path: 'register/:tokenRegister', element: <PreRegisterPage /> },

    ],
  },
];
