/*****************************************************************************
 * Copyright(c) 2023 Qorus Inc
 * All rights reserved
 *****************************************************************************
 * NAME: events.tsx
 * DEVELOPER: Favio Figueroa
 * DESCRIPTION: 
 * REVISIONS:
 * Date Change      ID              Author              Description
 * -----------      -----------     --------------      ------------------------------------
 * 05-AUG-2023      SP11AUG23       Favio Figueroa      Created
 * 19-SEP-2023      SP22SEP23       Favio Figueroa      Added addParams and deleteParams for re fetch
 * 13-NOV-2023      MAIN            Favio Figueroa      Added logic for AutoCompleteMultiple
 * 30-NOV-2023      SP24NOV23       Favio Figueroa      Added Logic for switch
 *****************************************************************************/

import { StateFormDetailInterface, StatesFormInterface} from '../types/config';
import * as Yup from 'yup';
import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const _ = require('lodash');


interface HandleChangeProps {
  e: any;
  states: StatesFormInterface;
  nameKey: string;
  dataValue?: any;
}

export const handleChange = ({ e, states, nameKey, dataValue }: HandleChangeProps) => {
  //e && e.preventDefault();

  const state = states[nameKey];

  //console.log('handleChange', e.target)

  let valueOfType;
  let value;
  switch (state.config.type) {
    case 'AutoComplete':
      valueOfType = dataValue ? dataValue : null;
      value = dataValue ? dataValue[state.config.store!.idDD] : null;
      if(dataValue === null) {
        state.dataStore?.deleteParams([state.config.store?.idDD]);
      }
      break;
    case 'AutoCompleteMultiple':
      valueOfType = dataValue ? dataValue : [];
      value = valueOfType;
      break;
    case 'GoogleReCaptchaV2':
      valueOfType = dataValue ? dataValue : null;
      value = valueOfType;
      break;
    case 'FilePond':
      valueOfType = dataValue ? dataValue : [];
      value = dataValue ? dataValue.map((dv:any) => dv.file) : [];
      break;
    case 'DatePicker':
      valueOfType = dataValue ? dataValue : null;
      value = valueOfType;
      break;
    case 'DateRangePicker':
      valueOfType = dataValue ? dataValue : null;
      value = valueOfType;
      break;
    case 'TimePicker':
      valueOfType = dataValue ? dataValue : null;
      value = valueOfType;
      break;
    case 'MobileTimePicker':
      valueOfType = dataValue ? dataValue : null;
      value = valueOfType;
      break;
    case 'Switches':
      valueOfType = dataValue ? dataValue : null;
      value = valueOfType;
      break;
    case 'Switch':
      valueOfType = e.target.checked;
      value = e.target.checked;
      break;
    case 'RadioButtons':
      valueOfType = e.target.value;
      value = e.target.value;
      break;
    case 'ToggleButton':
      valueOfType = dataValue;
      value = dataValue;
      break;
    case 'PaperButtons':
      valueOfType = dataValue;
      value = valueOfType;
      break;
    case 'TextField':
    case 'Dropdown':
      valueOfType =  e.target.value;
      value =  e.target.value;
      break;
    default:
      valueOfType = null;
      value = null;
      break;
  }

  console.log('handleChange',valueOfType)
  console.log('handleChange',dataValue)

  state.setValue(valueOfType);
  if (state.config.onChange) {
    console.log('handleChange 123',dataValue)
    console.log('handleChange 123',valueOfType)
    console.log('handleChange 123',value)

    state.config.onChange({e, value, state, states, valueOfType});
  }
};


// this handle has debounce for start with searching after 500 ms
export const handleInputChange = _.debounce(async (e:any, state:StateFormDetailInterface) => {
  const valueInput = e && e.target && e.target.value ? e.target.value: '';

  const { storeConfig: {isSearchable, minChars, filterConfig, axios }, requestState, setRequestState } = state.dataStore!;
  if(valueInput && isSearchable && valueInput !==0 && valueInput.length >= minChars) {

    state.dataStore?.addParams({
      ...(filterConfig ? {
        [filterConfig.filterName]: filterConfig.parFilters,
        [filterConfig.filterValue]: valueInput,
      } : {})
    });
  } else {
    state.dataStore?.deleteParams(['genericFilterFields', 'genericFilterValue']);
  }
}, 500);


export const getMetadata = (states: StatesFormInterface) => {
  const values = Object.entries(states).reduce(
    (t, [nameKey, state]) => ({
      ...t,
      ...((!state.hidden && state.metadata )&& {
        [nameKey]: state.metadata,
      } )

    }),
    {},
  );
  return values;
};
export const getValues = (states: StatesFormInterface) => {
  const values = Object.entries(states).reduce(
    (t, [nameKey, state]) => ({
      ...t,
      ...((!state.hidden || state.hidden) && { //todo here could we have problems with another logic for hide input
        // only send the inputs showed
        ...(state.config.type === 'DatePicker' && {
            [nameKey]: state.value ? moment(state.value).format(state.config.formatValue || state.config.inputFormat || 'DD-MM-YYYY') : null,
          }),
        ...(state.config.type === 'DateRangePicker' && {
            //[nameKey]: state.value ? moment(state.value.$d).format(state.config.formatValue || state.config.inputFormat || 'DD-MM-YYYY') : null,
            [nameKey]: state.value ? state.value.map((v:any) => moment(v.$d).format(state.config.formatValue || state.config.inputFormat || 'DD-MM-YYYY') )  : null,
          }),
        ...(state.config.type === 'TimePicker' && {
            [nameKey]: state.value ? moment(state.value).format(state.config.formatValue) : null,
          }),
        ...(state.config.type === 'MobileTimePicker' && {
            [nameKey]: moment(state.value).format("hh:mm:ss a"),
          }),
        /*...(state.type === 'TimePicker' && {
          [nameKey]: moment(state.value).format(state.format),
        }),*/
        ...(state.config.type === 'PaperButtons' && {
          [nameKey]: (state.value && state.value.value) || null,
          //[nameKey]: state.value,

        }),
        ...(state.config.type === 'AutoComplete' && {
          [nameKey]: (state.value && state.value[state.config.store!.idDD]) || null,
          //[nameKey]: state.value,

        }),
        ...(state.config.type === 'AutoCompleteMultiple' && {
          [nameKey]: (state.value && state.value) || [],
          //[nameKey]: state.value,

        }),
        ...(state.config.type === 'FilePond' && {
          [nameKey]: state.value ? state.value.map((v:any) => v.file) : null,
        }),
        ...(state.config.type === 'GoogleReCaptchaV2' && {
          [nameKey]: state.value,
        }),
        ...(state.config.type === 'Switches' && {
          [nameKey]: state.value,
        }),
        ...((state.config.type === 'Dropdown' ||
              state.config.type === 'TextField' ||
              state.config.type === 'RadioButtons' ||
              state.config.type === 'ToggleButton' ||
              state.config.type === 'Switch' /*||
              state.config.type === 'DropzoneArea' ||
              state.config.type === 'GoogleReCaptcha' ||
              state.config.type === 'Switch'*/) && {
          [nameKey]: state.value,
        }),
      } )

    }),
    {},
  );
  return values;
};

/*
const getDataForSending = (values, callback) => {
  let dataForSending;
  let type;
  const thereIsDropZoneArea = Object.entries(states).find(
      ([nameKey, value]) => value.type === 'DropzoneArea',
  );
  if (thereIsDropZoneArea) {
    const formData = new FormData();
    Object.entries(values).forEach(([nameKey, value]) => {
      if (states[nameKey]) {
        // only the input in state , no extraParams
        if (states[nameKey].type === 'DropzoneArea') {
          for (let i = 0; i < value.length; i++) {
            // formData.append(`${nameKey}[]`, value[i]);
            formData.append(nameKey, value[i]);
          }
        } else {
          formData.append(nameKey, value);
        }
      } else {
        formData.append(nameKey, value); // added extraParams
      }
    });
    dataForSending = formData;
    type = 'upload';
  } else {
    dataForSending = values;
  }
  callback(dataForSending, type);
};*/


export const resetForm = (states: StatesFormInterface) => {
  Object.values(states).forEach((state) => {
    state.reset();
  });
};


export const handleBlur = (state: StateFormDetailInterface) => {
  if (state.yupValidate) {
    const schemaOnlyField = Yup.object().shape({
      [state.nameKey]: state.yupValidate.shape,
    });
    schemaOnlyField
      .validateAt(state.nameKey, { [state.nameKey]: state.value })
      .then(() => {
        state.setError({ hasError: false, msg: '' });
      })
      .catch((err) => {

        state.setError({ hasError: true, msg: err.message });
      });
  }
};

export const handleMouseTriggerComponent = (event:any) => event.preventDefault();
